import { createContext, useContext, useState } from "react";


const AuthContext = createContext();

export default function AuthProvider({children}) {

  const [userState, setUserState] = useState(null);

  return(
    <AuthContext.Provider
      value={{
        userState,
        setUserState
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext);