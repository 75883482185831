import { Box, Button, Card, CardContent, CircularProgress, Link, TextField, Typography, styled } from "@mui/material";
import "./LoginView.css";
import "../../widgets/navBar/NavBarItem.css";
import nice_to_see_you from "../../assets/nice_to_see_you.png";
import { useRef, useState } from "react";
import { ADMIN_URL, frontend_version, GATEWAY_URL, WIV_LOGO, WIV_LOGO_ONLY } from "../../js/defines";
import { callApiAsync } from "../../js/helper";
import { useAuth } from "../../providers/AuthProvider";


const StyledTextField = styled(TextField)({
  "& label": {
    color: "#a4a498"
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#a4a498"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#a4a498",
      borderRadius: 12
    },
    "&:hover:not(&.Mui-focused) fieldset": {
      borderColor: "#a4a498",
      borderWidth: 2
    },
  }
});

function LoginView(props) {

  const { userState, setUserState } = useAuth();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const passwordRef = useRef();

  const [loading, setLoading] = useState(false);

  const [usernameErr, setUserNameErr] = useState(false); //kwf-poicfg wiv-kjb kwf-901-admin001
  const [passwordErr, setPasswordErr] = useState(false); //Wk8cqxKa J5588JPr jkcmEUe5

  async function onLogin(username, password) {
    const header = new Headers();
    header.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "userName": username,
      "password": password
    });

    const requestOptions = {
      method: "POST",
      headers: header,
      body: raw,
      redirect: "follow"
    };

    let url = GATEWAY_URL + ADMIN_URL + "/api/Auth/Login";
    setLoading(true);

    callApiAsync(url, requestOptions, (resp) => {
      if(resp.status != 400) {
        if(resp.token != null) {
          setUserState(resp);
        }
      } else {
        setUserNameErr(true);
        setPasswordErr(true);
      }
      setLoading(false);
    })
  }

  return (
    <div className="bg">
     <div className="bg-overlay"/>
      <Card sx={{ width: 375, borderRadius: "16px", justifySelf: "center", margin: "auto", backgroundColor: "#222", padding: "16px", zIndex: 10, position: "relative" }}>
        <CardContent style={{display: "flex", flexDirection: "column", gap: "32px"}}>
          <div style={{display: "flex", flexDirection: "column", gap: "32px", position: "relative"}}>

            <img
              src={WIV_LOGO_ONLY}
              alt="WOOD.IN.VISION Logo"
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: 350,
                margin: "auto",
                userSelect: "none",
                animation: "blurAnimationBG 4s ease-in forwards"
              }}
              onDragStart={(e) => e.preventDefault()}
            />

            {/*LOGO SECTION*/}
            <div style={{display: "flex", flexDirection: "column", gap: "16px"}}>
              <div style={{display: "flex", position: "relative", justifyContent: "center", marginBottom: 8}}>
                <img src={WIV_LOGO} alt="WOOD.IN.VISION Logo" width="275px" style={{ userSelect: "none", animation: "blurAnimation 4s ease-in forwards", opacity: 0.85 }} onDragStart={(e) => e.preventDefault()}/>
                <img src={WIV_LOGO} alt="WOOD.IN.VISION Logo" width="275px" style={{ userSelect: "abso", position: "absolute" }} onDragStart={(e) => e.preventDefault()}/>
              </div>
              <div style={{display: "flex", position: "relative", justifyContent: "center"}}>
                <img src={nice_to_see_you} alt="WOOD.IN.VISION Logo" width="250px" style={{ userSelect: "abso" }} onDragStart={(e) => e.preventDefault()}/>
              </div>
            </div>

            {/*LOGIN SECTION*/}
            <div style={{display: "flex", flexDirection: "column", gap: "24px", paddingInline: "16px"}}>
              <StyledTextField
                label={"Benutzername"}
                error={usernameErr}
                sx={{ input: { color: 'white' } }}
                onKeyDown={(e) => e.key === "Enter" ? passwordRef.current.focus() : null}
                onChange={(e) => setUsername(e.target.value)}
              />
              <StyledTextField
                inputRef={passwordRef}
                label={"Passwort"}
                type="password"
                error={passwordErr}
                sx={{ input: { color: 'white' } }}
                onKeyDown={(e) => e.key === "Enter" ? onLogin(username, password) : null}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            {/*LOGIN BUTTON*/}
            <Box sx={{ marginInline: "16px", position: 'relative' }}>
              <Button
                variant="contained"
                style={{
                  height: 48,
                  borderRadius: 12
                }}
                fullWidth
                disabled={loading}
                onClick={() => {
                  onLogin(username, password);
                }}
              >
                Login
              </Button>
              {loading && (<CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }}/>)}
            </Box>

            {/*LINK SECTION*/}
            <div style={{display: "flex", alignItems: "center"}}>
              <Link component="button" variant="body2" color={"#a4a498"} style={{margin: "auto", textDecoration: "none"}} onClick={() => window.open("https://wood-in-vision.com/")}>
                Website
              </Link>
              <Link component="button" variant="body2" color={"#a4a498"} style={{margin: "auto", textDecoration: "none"}} onClick={() => window.open("https://wood-in-vision.com/impressum")}>
                Impressum
              </Link>
            </div>            
          </div>
        </CardContent>
        <Typography color={"white"} fontSize={12} style={{position: "absolute", bottom: "8px", right: "16px", color: "#a4a498"}}>v{frontend_version}</Typography>
      </Card>
    </div>
  );
}

export default LoginView;
