import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Button, Slider, Box } from "@mui/material";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import FileIconButton from "./FileIconButton";


export default function ProfilePictureChooser({open, onSubmit}) {
  const avatarEditorRef = useRef();
  const [file, setFile] = useState(null);
  const [scale, setScale] = useState(false);

  const handleFileSelect = (file) => {
    setFile(file);
  };

  function handleSave() {
    const canvas = avatarEditorRef.current.getImageScaledToCanvas().toDataURL();
    if(onSubmit != null) onSubmit(canvas);
  }

  return(
    <Dialog open={open}>
      <DialogTitle>
        Choose Profile Picture
      </DialogTitle>
      <DialogContent>
        <Box>
          <AvatarEditor
            ref={avatarEditorRef}
            image={file}
            width={250}
            height={250}
            rotate={0}
            scale={1 + (scale * 0.05)}
            borderRadius={125}
            disableHiDPIScaling
          />
          <Stack direction={"row"} spacing={"24px"}>
            <FileIconButton onFileSelect={handleFileSelect} accept={".png, .jpeg, .jpg"}/>
            <Slider style={{marginRight: 16, alignSelf: "center"}} value={scale} onChange={(e) => setScale(e.target.value)}/>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSave()}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}