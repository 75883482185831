import { useAuth } from "../providers/AuthProvider";
import DataProvider from "../providers/DataProvider";
import SignalRProvider from "../providers/SignalRProvider";
import LoginView from "./loginView/LoginView";
import MainView from "./mainView/MainView";

export default function Main() {

  const { userState, setUserState } = useAuth();

  return(
    <>
      {userState?.token && userState?.userState?.username ?
        <SignalRProvider userState={userState} bearerToken={userState?.token}>
          <DataProvider userState={userState}>
            <MainView/>
          </DataProvider>
        </SignalRProvider>
      :
        <LoginView/>
      }
    </>
  );
}