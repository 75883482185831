import React from 'react'
import SelectedPoiListProvider from './SelectedPoiProvider';
import ExportedImageProvider from './MapProvider';
import TableProvider from './TableProvider';
import AuthProvider from '../providers/AuthProvider';

const Providers = ({children}) => {
  return (
    <>
      <AuthProvider>
        <SelectedPoiListProvider>
          <ExportedImageProvider>
            <TableProvider>
              {children}
            </TableProvider>
          </ExportedImageProvider>
        </SelectedPoiListProvider>
      </AuthProvider>
    </>
  )
}

export default Providers