import "../../App.css";
import "./MapView.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useResizeObserver } from "../../helper/ResizeObserver";
import BasicMap from "../../widgets/basicMap/BasicMap";
import { Avatar, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
import { alphabeticSort, getSimpleLabel, parseSaveJson } from "../../js/helper";
import { Add } from "@mui/icons-material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TabViewParentItem from "../../widgets/webView/TabViewParentItem";
import TabViewChildItem from "../../widgets/webView/TabViewChildItem";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from "@emotion/react";
import TabViewTable from "../../widgets/webView/TabViewTable";
import { BarChart } from '@mui/x-charts/BarChart';
import { useMap } from "../../context/MapProvider";
import { useServerData } from "../../providers/DataProvider";
import Lama from "../../easterEggs/lama";
import { useTranslation } from "react-i18next";

function InfoCharts(props) {
  const theme = useTheme();
  
  let treeList = props.poiList && props.poiList["global/mechanical_timber_list"] ? props.poiList["global/mechanical_timber_list"] : [];

  let volType = "log_volume";
  var filteredListVolume = []
  var filterListCount = []
  var totalVolume = 0;
  var totalCnt = 0;
  treeList.forEach(element => {
    if(element.poi.log_type != "Unclassified") {
      var key = element.poi.log_species + " " + element.poi.log_type
      if (filteredListVolume[key] == null) {
        filteredListVolume[key] = 0;
      }
      if (filterListCount[key] == null) {
        filterListCount[key] = 0;
      }

      totalVolume += (element.poi[volType] * 0.0001)
      totalCnt += 1
      filteredListVolume[key] = filteredListVolume[key] + (element.poi[volType] * 0.0001)
      filterListCount[key] = filterListCount[key] + 1
    }
  });
  
  //Generate bar chart data
  var barSeries = []
  var barData = []
  Object.entries(filteredListVolume).sort((a, b) => parseFloat(b[1]) - parseFloat(a[1])).map((row) => { 
    barSeries.push(row[0])
    barData.push(row[1].toFixed(2))
  })

  return (
    <>
      <div
        style={{
          position: "absolute",
          display: "flex",
          padding: "10px",
          flexDirection: "column",
          width: "300px",
          backgroundColor: theme.palette.background.default,
          borderRadius: "20px",
          color: "white",
          left: "86px",
          bottom: props.tableHeight + 16,
          visibility: props.diagrammOpen ? "visible" : "hidden",
          zIndex: 1,
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography style={{ color: theme.palette.text.primary, alignSelf: "center", fontSize: 18, fontWeight: 600 }}>
            Diagramm
          </Typography>
        </div>

        <BarChart
          xAxis={[
            {
              id: 'barCategories',
              data: barSeries,
              scaleType: 'band',
            },
          ]}
          series={[
            {
              data: barData,
              color: theme.palette.primary.main
            },
          ]}
          width={300}
          height={300}
        />

        <Typography style={{ color: theme.palette.text.primary, fontSize: 18, fontWeight: 600 }}>
          FM: {totalVolume.toFixed(3)}
        </Typography>

        <Typography style={{ color: theme.palette.text.primary, fontSize: 18, fontWeight: 600 }}>
          Anzahl: {totalCnt}
        </Typography>

      </div>
    </>
  );
}

function BuddyList(props) {
  const theme = useTheme();

  const { me, buddies } = useServerData();

  console.log(buddies)

  return (
    <>
      <div
        style={{
          position: "absolute",
          display: "flex",
          padding: "10px",
          flexDirection: "column",
          width: "300px",
          backgroundColor: theme.palette.background.default,
          borderRadius: "20px",
          color: "white",
          left: "86px",
          bottom: props.tableHeight + 16,
          visibility: props.diagrammOpen ? "visible" : "hidden",
          zIndex: 1,
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography style={{ color: theme.palette.text.primary, alignSelf: "center", fontSize: 18, fontWeight: 600 }}>
            Buddies
          </Typography>
        </div>

        <List sx={{ width: '100%', maxWidth: 360, maxHeight: 300, overflowY: "scroll"}}>
          {buddies?.filter((i) => i.id != me?.id).length > 0 ?
            buddies?.filter((i) => i.id != me?.id).map((buddy) => (
              <ListItem style={{backgroundColor: "#ddd", borderRadius: 12, marginBlock: 8}}>
                <ListItemAvatar>
                  <Avatar alt={buddy.fullName} src={buddy?.profilePictureBase64} style={{border: buddy.connectionId != "" ? "3px solid #090" : "3px solid #888"}}/>
                </ListItemAvatar>
                <ListItemText primary={buddy?.fullname} secondary={buddy?.userName} style={{ color: theme.palette.text.primary }} />
              </ListItem>
            ))
          :
            <div>
              <Typography style={{ color: theme.palette.text.primary, textAlign: "center" }}>No Buddys</Typography>
            </div>
        }
        </List>
      </div>
    </>
  );
}

function AddModuleButton({cfgs, onPoiAddedToTabView}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { activeCfgs } = useServerData();

  const [searchText, setSearchText] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleAdd(config) {
    setAnchorEl(null);
    onPoiAddedToTabView(config);
  }

  //Reset Search Field
  useEffect(() => {
    if(open) {
      setSearchText("");
    }
  }, [open])

  const filteredCfgs = useMemo(() => (
    cfgs.filter((i) => !activeCfgs.map((j) => j.id).includes(i.id) && i.isIndependent == true && i.tableName.toLowerCase().includes(searchText.toLowerCase()))
  ), [cfgs, activeCfgs, searchText]);

  return(
    <>
      <Tooltip title="Neuer Tabellen-Tab" placement="right">
        <span>
          <IconButton size="small" style={{margin: "3px"}} onClick={handleClick}><Add/></IconButton>
        </span>
      </Tooltip>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={1}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: theme.palette.background.default,
            borderRadius: "12px"
          },
        }}
        onClose={() => handleAdd(null)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: 200,
            minHeight: 100,
            maxWidth: 300,
            maxHeight: "50vh",
            textAlign: "center",
            paddingInline: 8,
            gap: 8
          }}
        >
          <Typography fontWeight={600}>{t("select_module")}</Typography>
          <div style={{display: "flex", flexDirection: "column", overflowY: filteredCfgs.length > 0 ? "auto" : "hidden", overflowX: "hidden"}}>
            {filteredCfgs.length > 0 ? 
              filteredCfgs?.sort(alphabeticSort).map((config) => (
                <MenuItem key={config.uniqueKey} onClick={() => handleAdd(config)} style={{gap: "8px"}}>
                  <img src={config.tableIcon} style={{width: "24px", userSelect: "none", backgroundColor: config.tableIconBgColor, borderRadius: 6, padding: "1px"}}></img>
                  <Typography style={{textOverflow: "ellipsis", overflow: "hidden"}}>{config.tableName}</Typography>
                </MenuItem>
              ))
            :
            <Typography style={{height: 48, alignContent: "center"}} fontSize={12}>{activeCfgs.length > 0 ? "No more modules" : "No modules"}</Typography>
          }
          </div>
          <TextField fullWidth size="small" label="Search" placeholder="1.1 Flächenbearbeitung" value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
        </div>
      </Menu>
    </>
  )
}

function MapView(props) {
  const content = useRef(null);
  const theme = useTheme();
  useResizeObserver(content);
  let userState = props.userState;

  const {
    buddies,
    userSettings,
    serverSyncState,
    cfgs,
    activeCfgs,
    selectedCfg,
    styles,
    selectedStyles,
    dataEntries,
    mapConfigs,
    selectedPois,
    selectedRows,
    selectedFilters,
    enabledBaseLayer,
    enabledLayers,
    enabledFavLayer,
    selectedPoiStyle,
    selectedMapStyle,
    selectedTableRows,
    selectedTableFilters,
    hasMapLayer,
    selectedPoisByUniqueId,
    setActiveCfgs,
    setSelectedCfg,
    setDataEntries,
    setMapConfigs,
    setSelectedPois,
    setSelectedRows,
    setSelectedFilters,
    getTableRows,
    saveUserSetting
  } = useServerData();

  const [appView, setAppView] = useState(false);
  const { mapData } = useMap();
  const [getWidth, setGetWidth] = useState(0);
  const [editGeometry, setEditGeometry] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const [heightState, setHeightState] = useState(userSettings?.heightState ?? 1);
  const [minimizedHeightState, setMinimizedHeightState] = useState(null);
  const [height, setHeight] = useState(window.innerHeight / 3);

  const [favLayerArr, setFavLayerArr]= useState(userSettings?.favLayers ?? []); //Open Selected Poi Rows
  useEffect(() => setFavLayerArr(userSettings?.favLayers ?? []), [userSettings?.favLayers]);

  
  function getPoiUniqueId() {
    return selectedCfg ? selectedCfg.uniqueKey : "";
  }


  function selectCurrentPoiConfig(parentCfg, cfg) {
    let newCfg = cfg;
    if(parentCfg?.uniqueKey) {
      newCfg.parentUniqueKey = parentCfg?.uniqueKey;
    } else {
      delete newCfg.parentUniqueKey;
    }
    setSelectedCfg(newCfg);
    updatePoiList(newCfg);
  }

  //Update currently active cfg
  useEffect(() => {
    let newSelectedCfg = cfgs?.find((i) => i.id == selectedCfg?.id);
    if(newSelectedCfg) {
      newSelectedCfg.parentUniqueKey = selectedCfg.parentUniqueKey;
    }
    setSelectedCfg(newSelectedCfg);
  }, [cfgs]);

  function updatePoiList(poiConfig, newSelectedRows) {
    setSelectedCfg(poiConfig);
    if (poiConfig != null) {
      getTableRows(poiConfig)
    }
  }


  //WebView - TableView
  function tableRowsChanged(poiUniqueId, rows, action) {
    let newDataEntries = {...dataEntries};
    if(action == "delete") {
      rows.forEach((guid) => {
        let foundIdx = newDataEntries[poiUniqueId].findIndex((item) => item.id == guid);
        console.log(foundIdx);
        if(foundIdx > -1) {
          newDataEntries[poiUniqueId].splice(foundIdx, 1);
        }
      });
      setDataEntries(newDataEntries);
    } else {
      rows.forEach((row) => {
        if(!newDataEntries[poiUniqueId]) {
          newDataEntries[poiUniqueId] = [];
        }
        let foundIdx = newDataEntries[poiUniqueId]?.findIndex((item) => item.id == row.id) ?? -1;
        if(foundIdx > -1) {
          newDataEntries[poiUniqueId][foundIdx] = row;
        } else {
          newDataEntries[poiUniqueId].push(row);
        }
      });
      setDataEntries(newDataEntries);
    }
  }


  const geometryChanged = useCallback((kmlString) => {
    console.log(kmlString);
  });

  function onPoiAddedToTabView(config) {
    if(config != null) {
      let newSelectedPoiConfigs = [...activeCfgs];
      //Add it to the list
      if(!newSelectedPoiConfigs.includes(config)) {
        newSelectedPoiConfigs.push(config);
        setActiveCfgs(newSelectedPoiConfigs);
      }
      selectCurrentPoiConfig(null, config);
    }
  };


  function clearTableData(selectedPois, selectedRows, dataEntries, cfg) {
    //RESET ALL VALUES
    delete selectedPois[cfg.uniqueKey];
    delete selectedRows[cfg.uniqueKey];
    delete dataEntries[cfg.uniqueKey];
    let cols = []
    cols = cols.concat(cfg?.columns?.filter((i) => i.fieldType == "NestedTable"));
    cols = cols.concat(cfg?.columns?.filter((i) => i.fieldType == "DynamicList"));
    cols.map((col) => {
      let colCfg = parseSaveJson(col.configurationsJson);
      let table = cfgs.find((i) => i.id == colCfg.tableId)
      if(table) {
        let newData = clearTableData(selectedPois, selectedRows, dataEntries, table);
        selectedPois = newData.selectedPois;
        selectedRows = newData.selectedRows;
        dataEntries = newData.dataEntries;
      }
    })
    return({
      selectedPois: selectedPois,
      selectedRows: selectedRows,
      dataEntries: dataEntries
    })
  }

  function onPoiRemovedFromTabView(config) {
    if(config != null) {
      let newData = clearTableData(selectedPois, selectedRows, dataEntries, config)
      setSelectedPois(newData.selectedPois);
      setSelectedRows(newData.selectedRows);
      setDataEntries(newData.dataEntries);
      var newSelectedConfigs = activeCfgs.filter(innerConfig => (innerConfig.uniqueKey) !== (config.uniqueKey));
      setActiveCfgs(newSelectedConfigs);
      if(selectedCfg === config) {
        setSelectedCfg(null);
        setAppView(false);
      }
    }
  };


  //Map Handlers
  function onMapConfigChanged(poiUniqueId, type, value) {
    var newMapConfigs = {...mapConfigs};
    if(newMapConfigs[poiUniqueId] == null) {
      newMapConfigs[poiUniqueId] = {
        showIcons: false,
        showLayer: false
      }
    }
    newMapConfigs[poiUniqueId][type] = value;
    if(value === true) {
      handleSelectedRows(poiUniqueId, selectedRows[poiUniqueId] ? selectedRows[poiUniqueId] : []);
    }
    setMapConfigs(newMapConfigs);
  }

  async function handleSelectedRows(poiUniqueId, rows) {
    console.log("CALLED")
    let innerSelectedPois = [];

    let entries = dataEntries[selectedCfg?.uniqueKey];
    var poiDict = entries ? Object.fromEntries(entries.map(x => [x.id, x])) : entries;

    if(poiDict) {
      for(var rowId of rows) {
        let entry = poiDict[rowId];
        if(entry != null) {
          innerSelectedPois.push(entry);
        }
      }
    }

    let newSelectedRows = {...selectedRows};
    if(newSelectedRows[poiUniqueId] == null) {
      newSelectedRows[poiUniqueId] = []
    }
    newSelectedRows[poiUniqueId] = rows;
    setSelectedRows(newSelectedRows);

    let newSelectedPois = {...selectedPois};
    if(newSelectedPois[poiUniqueId] == null) {
      newSelectedPois[poiUniqueId] = []
    }
    newSelectedPois[poiUniqueId] = innerSelectedPois;
    setSelectedPois(newSelectedPois);
  }

  let onMapMoved = useCallback(async (mapPos) => {
    saveUserSetting("mapPos", mapPos);
  }, [userSettings]);


  //Height Control
  function handleExpand() {
    if(heightState < 4) {
      let newHeightState = heightState + 1;
      setHeightState(newHeightState);
      handleHeight(newHeightState);
      saveUserSetting("heightState", newHeightState);
      setMinimizedHeightState(null);
      
    }
  }

  function handleShrink() {
    if(heightState > 0) {
      let newHeightState = heightState - 1;
      setHeightState(newHeightState);
      handleHeight(newHeightState);
      saveUserSetting("heightState", newHeightState);
      setMinimizedHeightState(null);
    }
  }

  function toggleMaximizeTable() {
    let hState = heightState;
    let mhState = minimizedHeightState;
    if(mhState == null) {
      setMinimizedHeightState(hState);
      mhState = hState;
      setHeightState(4);
      hState = 4;
    } else {
      setHeightState(mhState);
      hState = mhState;
      setMinimizedHeightState(null);
      mhState = null;
    }
    handleHeight(hState);
  }

  const handleHeight = useCallback((newHeightState) => {
    switch(newHeightState) {
      case 0:
        setHeight(40);
        break;
      case 1:
        setHeight(window.innerHeight / 3);
        break;
      case 2:
        setHeight(window.innerHeight / 2);
        break;
      case 3:
        setHeight(window.innerHeight / 1.5);
        break;
      case 4:
        setHeight(window.innerHeight);
        break;
        default:
        setHeight(window.innerHeight);
          return
    }
  }, [heightState]);

  window.addEventListener("resize", (event) => {
    handleHeight(heightState);
  });

  useEffect(() => {
    let rows = selectedRows[getPoiUniqueId()];
    if(rows) {
      handleSelectedRows(getPoiUniqueId(), rows);
    }
  }, [dataEntries, buddies]);

  useEffect(() => {
    handleHeight(heightState);
  }, []);

  let selectedTabCnt = 0;
  let maxTabWidth = (100.0 / (selectedTabCnt));


  let showGeomIndicator = useMemo(() => {
    return(serverSyncState > 0);
  }, [serverSyncState]);

  let showStyleIndicator = useMemo(() => {
    return(serverSyncState == -1);
  }, [serverSyncState]);

  const tableViewMemo = useMemo(() => 
    <TabViewTable
      height={height}
      width={getWidth}
      appView={appView}
      selectedPoisByUniqueId={selectedPoisByUniqueId}
      userSettings={props.userSettings}
      userState={props.userState}
      bearerToken={props.bearerToken}
      disableButtons={showGeomIndicator}
      onTableRowsChanged={tableRowsChanged}
      onFilterModelChange={(poiUniqueId, newFilterModel) => {
        let newSelectedFilters = {...selectedFilters};
        newSelectedFilters[poiUniqueId] = newFilterModel;
        setSelectedFilters(newSelectedFilters);
      }}
      onRowSelectionModelChange={async (poiUniqueId, rows, forceUpdateChilds, childConfig) => {
        mapData.changeIgnoreZoomOnGeometryFunction(false);
        if(!editGeometry) {
          if(selectedCfg) {
            handleSelectedRows(poiUniqueId, rows);
            if(forceUpdateChilds) {
              updatePoiList(childConfig, rows);
            }
          } else {
            handleSelectedRows("", rows)
          }
        } else {
          setSnackMessage("Geometriebearbeitung zuvor beenden")
        }
      }}
      onMapConfigChanged={onMapConfigChanged}
      editGeometry={editGeometry}
      onEditGeometry={(active) => {
        if(active) {
          let newMapConfigs = {...mapConfigs};
          let cfg = newMapConfigs[selectedCfg?.uniqueKey];
          if(cfg == null) {
            cfg = {}
            newMapConfigs[selectedCfg?.uniqueKey] = cfg;
          }
          cfg["showLayer"] = true;
          setMapConfigs(newMapConfigs);
        }
        setEditGeometry(active)
      }}
    />
    , [
      height,
      getWidth,
      appView,
      userState,
      selectedPoisByUniqueId,
      props.userSettings,
      dataEntries,
      cfgs,
      mapConfigs,
      selectedCfg,
      selectedRows,
      selectedTableRows,
      showGeomIndicator,
      styles,
      selectedPoiStyle,
      selectedMapStyle,
      editGeometry,
      selectedTableFilters,
      buddies
    ]
  );

  const renderConfigItem = (parentCfg, selectedCfg, selectedRows, maxTabWidth, tabSize, height, selectCurrentPoiConfig, onPoiRemovedFromTabView, cfgs) => {
    return (
      <>
        {parentCfg.columns
        .filter((column) => column.fieldType === "NestedTable")
        .map((child) => {
          try {
            if (!child.configurationsJson) return null;
            const configurationsJson = JSON.parse(child.configurationsJson);
            const innerCfg = cfgs.find((cfg) => cfg.id === configurationsJson.tableId);

            if (!innerCfg) return null;

            const childSelected = selectedCfg != null && (innerCfg.uniqueKey === selectedCfg.uniqueKey && parentCfg.uniqueKey == selectedCfg.parentUniqueKey);

            return (
              <div key={innerCfg.uniqueKey} style={{ display: "flex", gap: "2px", overflow: "hidden" }}>
                <TabViewChildItem
                  key={innerCfg.uniqueKey}
                  config={innerCfg}
                  selected={childSelected}
                  selectedRows={selectedRows}
                  height={height}
                  maxWidth={maxTabWidth}
                  tabSize={tabSize}
                  onPoiSelect={() => selectCurrentPoiConfig(parentCfg, innerCfg)}
                  onPoiClose={() => onPoiRemovedFromTabView(innerCfg)}
                />
                {/* Recursive call */}
                {renderConfigItem(innerCfg, selectedCfg, selectedRows, maxTabWidth, tabSize, height - 2, selectCurrentPoiConfig, onPoiRemovedFromTabView, cfgs)}
              </div>
            );
          } catch (e) {
            console.error("Error parsing configurationsJson or rendering nested table", e);
            return null;
          }
        })}
      </>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "calc(100svh)", overflow: "hidden" }}>
      <div style={{ display: "flex", width: "100%", height: "calc(100svh - " + height + "px)", overflow: "hidden" }}>
        {/*MAP*/}
        <div style={{width: "calc(100% - " + getWidth + "px)", height: "calc(100svh - " + height + "px)"}}>
          <BasicMap
            width={getWidth}
            appView={appView}
            height={height}
            cfgList={cfgs}
            mapConfig={mapConfigs}
            configs={props.configs}
            poiList={dataEntries}
            hasMapLayer={hasMapLayer}
            selectedPois={selectedPois}
            selectedPoiConfig={selectedCfg}
            gisStyles={styles}
            selectedStyle={selectedStyles}
            selectedPoisByUniqueId={selectedPoisByUniqueId}
            favLayerArr={favLayerArr}
            enabledFavLayer={enabledFavLayer}
            enabledBaseLayer={enabledBaseLayer}
            enabledLayers={enabledLayers}
            editGeometry={editGeometry}
            onMapMoved={onMapMoved}
            onGeometryChanged={geometryChanged}
            bearerToken={props.bearerToken}
            userSettings={props.userSettings}
            username={props.username}
            password={props.password}
          />
        </div>
      </div>
      {/*WEBVIEW*/}
      {height > 0 ? 
        <div style={{width: "calc(100vw)", height: height, overflow: "hidden", zIndex: 2}}>
          {/*TABVIEW FOR LIST VIEW*/}
          <div style={{width: "calc(100vw - " + 70 + "px)", height: "40px", display: "flex", gap: "6px", backgroundColor: theme.palette.background.paper}} onDoubleClick={toggleMaximizeTable}>
            {activeCfgs.map(config => {
              var selected = selectedCfg != null && (config.uniqueKey === selectedCfg.uniqueKey && !selectedCfg.parentUniqueKey);
              return(
                <div key={config.uniqueKey} style={{display: "flex", gap: "2px", overflow: "hidden"}}>
                  <TabViewParentItem
                    key={config.uniqueKey}
                    config={config}
                    selected={selected}
                    selectedRows={selectedRows}
                    maxWidth={maxTabWidth}
                    tabSize={props.tabSize}
                    onPoiSelect={() => selectCurrentPoiConfig(null, config)}
                    onPoiClose={() => onPoiRemovedFromTabView(config)}
                  />
                  {renderConfigItem(config, selectedCfg, selectedRows, maxTabWidth, props.tabSize, 32, selectCurrentPoiConfig, onPoiRemovedFromTabView, cfgs)}
                </div>
              );
            })}
            
            <AddModuleButton cfgs={cfgs} onPoiAddedToTabView={onPoiAddedToTabView}/>

            <div style={{display: "flex", marginLeft: "auto", gap: "6px", marginRight: "2px"}}>
              {showGeomIndicator ? 
                <>
                  <Typography style={{alignSelf: "center", marginRight: "10px", userSelect: "none", color: theme.palette.text.primary}}>Lade Geometrien...</Typography>
                  <LinearProgress variant="determinate" value={serverSyncState * 100} style={{width: "150px", alignSelf: "center", marginRight: "20px"}}/>
                </> 
              : null}
              {showStyleIndicator ? 
                <>
                  <Typography style={{alignSelf: "center", marginRight: "10px", userSelect: "none", color: theme.palette.text.primary}}>Lade Styles...</Typography>
                  <LinearProgress variant="indeterminate" value={serverSyncState * 100} style={{width: "150px", alignSelf: "center", marginRight: "20px"}}/>
                </> 
              : null}
                <IconButton size="small" style={{height: "34px", width: "34px", marginBlock: "3px"}} disabled={!(heightState < 4)} onClick={handleExpand}><ArrowDropUpIcon/></IconButton>
                <IconButton size="small" style={{height: "34px", width: "34px", marginBlock: "3px"}} disabled={!(heightState > 0)} onClick={handleShrink}><ArrowDropDownIcon/></IconButton>
            </div>
          </div>
          {height > 40 ? 
            tableViewMemo
          : null}
        </div>
        : null
      }

      <Lama height={height}/>

      {/*<BuddyList diagrammOpen={true} tableHeight={height}/>

      <InfoCharts diagrammOpen={diagrammOpen} tableHeight={height} poiList={poiList}/>*

      <BuddyList diagrammOpen={true} tableHeight={height}/>/}

      <div style={{position: "absolute", top: "220px", right: "16px", backgroundColor: "#fff", padding: "10px", whiteSpace: "pre-line", maxHeight: "50vh", maxWidth: "300px", overflowX: "hidden", overflowY: "scroll"}}>
        <p>
          {"dataEntries: " + JSON.stringify(Object.entries(dataEntries).map(([key, value]) => (key + ": " + value?.length)), null, 2) + "\n"}
        </p>
      </div>*/}
    </div>
  );
}

export default MapView;