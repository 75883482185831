import { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Autocomplete, Box, Breadcrumbs, Button, ButtonBase, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, Link, MenuItem, OutlinedInput, Select, Stack, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { callApiAsync, callApiRawAsync, callApiTextAsync, generatePassword, ObjectID, parseSaveJson } from "../../js/helper";
import { Add, AddAPhoto, DeleteForever, DeleteForeverOutlined, InfoOutlined, NavigateNext, Refresh, Save } from "@mui/icons-material";
import { useSignalR } from "../../providers/SignalRProvider";
import ProfilePictureChooser from "../../widgets/ProfilePictureChooser";
import { GridToolbarContainer, useGridApiRef } from "@mui/x-data-grid";
import { ADMIN_URL, GATEWAY_URL } from "../../js/defines";
import { useServerData } from "../../providers/DataProvider";
import {v4 as uuidv4} from 'uuid';
import TimedButton from "../../widgets/components/TimedButton";
import { DataGridPremium } from "@mui/x-data-grid-premium";


function EditToolbar(props) {
  const { selectedTables, apiRef } = props;

  const handleClick = () => {
    const id = uuidv4();
    let newItem = {
      id: id,
      CanCreate: true,
      CanDelete: true,
      CanEdit: true,
      EditableColumns: [],
      Filter: "",
      TableId: null,
      VisibleColumns: [],
      isNew: true,
    }
    apiRef.current.updateRows([newItem]);
  };

  const handleDelete = () => {
    apiRef.current.updateRows(selectedTables.map((id) => ({id, _action: "delete"})))
  };

  return (
    <GridToolbarContainer>
      <IconButton onClick={handleDelete}><DeleteForeverOutlined/></IconButton>
      <IconButton onClick={handleClick}><Add/></IconButton>
    </GridToolbarContainer>
  );
}

function GroupPage({bearerToken, selectedCompany, selectedGroup, onReload, users}) {
  const theme = useTheme();

  const { cfgs } = useServerData();
  const apiRef = useGridApiRef();

  const [name, setName] = useState(selectedGroup?.GroupName ?? "User");
  const [description, setDescription] = useState(selectedGroup?.Description ?? "Basic User Group");
  const [AllowedTables, setAllowedTables] = useState(selectedGroup?.AllowedTables?.map((i, idx) => { i.id = idx; return i; }) ?? []);

  const [selectedTables, setSelectedTables] = useState([]);

  const [groupToDelete, setGroupToDelete] = useState(null);

  function groupPOSTPUT() {
    let tmpGroup = JSON.parse(JSON.stringify(selectedGroup));

    tmpGroup.GroupName = name;
    tmpGroup.Description = description;

    let isNew = tmpGroup.isNew;

    //Rows
    const rowModels = apiRef.current.getRowModels();
    let allRows = Array.from(rowModels.values());
    allRows = allRows?.map((rule) => {
      return rule;
    });
    tmpGroup.AllowedTables = allRows;
    delete tmpGroup?.isNew;

    let url = GATEWAY_URL + ADMIN_URL + "/api/TableAccessConfigs" + (isNew ? "" : ("/" + tmpGroup._id.$oid));
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: isNew ? "POST" : "PUT",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(tmpGroup)
    };

    callApiRawAsync(url, requestOptions, (response) => {
      console.log(response)
      if(response.status < 300) {
        onReload();
      }
    });
  }

  function removeGroup() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/TableAccessConfigs/" + selectedGroup?._id?.$oid;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };

    console.log(requestOptions)

    callApiRawAsync(url, requestOptions, (response) => {
      console.log(response)
      if(response.status < 300) {
        setGroupToDelete(null);
        onReload();
      }
    });
  }

  const renderEditRole = (params) => {
    const rowModels = apiRef.current.getRowModels();
    let allRows = Array.from(rowModels.values());
    return (
      <Select
        value={params.value || ''}
        onChange={(event) => params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event)}
        autoFocus
        fullWidth
      >
        {params.colDef.valueOptions.map((option) => <MenuItem value={option.id} disabled={(allRows.findIndex((j) => j.TableId === option.id) > -1)}>{option.label}</MenuItem>)}
      </Select>
    );
  };

  const styleRuleCols = [
    { field: 'id', headerName: 'ID', width: 50, editable: false },
    {
      field: 'TableId',
      headerName: 'Table',
      type: "singleSelect",
      getOptionLabel: (value) => value.label,
      getOptionValue: (value) => value.id,
      valueOptions: cfgs.filter((i) => selectedCompany?.allowedTableIds?.includes(i.id)).map((i) => ({ id: i.id, label: i.tableName})),
      renderEditCell: renderEditRole,
      width: 150,
      editable: true,
      flex: 3
    },
    {
      field: 'Filter',
      headerName: 'Filter',
      width: 110,
      editable: true,
      flex: 3
    },
    {
      field: 'VisibleColumns',
      headerName: 'Visible Columns',
      type: "singleSelect",
      getOptionLabel: (value) => value.label,
      getOptionValue: (value) => value.id,
      valueOptions: [
        { id: "fill_color", label: "Fill Color" },
        { id: "stroke_color", label: "Stroke Color" },
        { id: "stroke_width", label: "Stroke Width" },
        { id: "circle_d", label: "Circle Diameter" },
        { id: "font_color", label: "Font Color" },
        { id: "font_bg_color", label: "Font Shadow" },
        { id: "font_size", label: "Font Size" },
        { id: "icon_url", label: "Icon URL" },
        { id: "label", label: "Label" }

      ],
      width: 110,
      editable: true,
      flex: 3
    },
    {
      field: 'EditableColumns',
      headerName: 'Editable Columns',
      type: 'singleSelect',
      getOptionLabel: (value) => value.label,
      getOptionValue: (value) => value.id,
      valueOptions: [
        { id: "webApp", label: "Web App" },
        { id: "mobileApp", label: "Mobile App" },
      ],
      width: 110,
      editable: true,
      flex: 3
    },
    {
      field: 'CanCreate',
      headerName: 'Can Create',
      type: 'boolean',
      width: 110,
      editable: true,
      flex: 1
    },
    {
      field: 'CanEdit',
      headerName: 'Can Edit',
      type: 'boolean',
      width: 110,
      editable: true,
      flex: 1
    },
    {
      field: 'CanDelete',
      headerName: 'Can Delete',
      type: 'boolean',
      width: 110,
      editable: true,
      flex: 1
    }
  ];

  function onSelectionModel(row) {
    setSelectedTables(row);
  }

  let allUsers = users.map((j) => ({id: j.id, label: `${j.userName} - ${j.fullname}`}))
  let usersInGroup = users.filter((i) => i.tableAccessConfigId == selectedGroup?._id?.$oid).map((j) => ({id: j.id, label: `${j.userName} - ${j.fullname}`}))

  return(
    <>
      <div style={{display: "flex", flexDirection: "column", gap: 64}}>
        <div style={{display: "flex", justifyContent: "space-between", border: "1px solid #ccc", borderRadius: 6, paddingInline: 8, paddingBlock: 4}}>
          <Typography style={{alignContent: "center"}} fontSize={24} fontWeight={600} color={theme.palette.text.primary}>Edit Group</Typography>
          <div style={{display: "flex", gap: 16}}>
            <IconButton onClick={() => setGroupToDelete(selectedGroup)}><DeleteForever style={{color: "red"}}/></IconButton>
            <IconButton onClick={() => { groupPOSTPUT() }}><Save/></IconButton>
          </div>        
        </div>

        <Typography fontSize={24} fontWeight={600} color={theme.palette.text.primary} style={{alignSelf: "center"}}>{name}</Typography>
        <div style={{display: "flex", flexDirection: "column", gap: 16, width: "50%", alignSelf: "center"}}>
          <TextField size="small" label="Group Name" required value={name} onChange={(e) => setName(e.target.value)}></TextField>
          <TextField size="small" label="Description" required value={description} onChange={(e) => setDescription(e.target.value)}></TextField>

          <Autocomplete
            fullWidth
            multiple
            size="small"
            value={usersInGroup}
            options={allUsers}
            renderInput={(params) => <TextField {...params} label="Users in Group" />}
            isOptionEqualToValue={(option, value) => option.id == value.id}
          />
        </div>
        <Box sx={{ height: "calc(100vh - 535px)", width: '100%' }}>
          <DataGridPremium
            apiRef={apiRef}
            rows={AllowedTables ?? []}
            columns={styleRuleCols}
            checkboxSelection
            onRowSelectionModelChange={onSelectionModel}
            editMode="row"
            density="compact"
            slots={{
              toolbar: EditToolbar
            }}
            slotProps={{
              toolbar: {
                AllowedTables,
                selectedTables,
                setAllowedTables,
                onReload,
                apiRef
              }
            }}
          />
        </Box>
      </div>

      <Dialog open={groupToDelete != null}>
        <DialogTitle>
          <div style={{display: "flex", gap: 8}}>
            <InfoOutlined style={{alignSelf: "center"}} color='error'/>
            <Typography fontSize={20}>Delete Group</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          Do you want to delete "{groupToDelete?.GroupName}"?
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "grey", width: "115px" }} onClick={() => setGroupToDelete(null)}>
            Cancel
          </Button>
          <TimedButton
            style={{ width: "115px" }}
            color={"error"}
            variant="contained"
            onClick={() => { removeGroup(groupToDelete) }}
            autoFocus
            timeout={10}
          >
            Delete
          </TimedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

function UserPage({bearerToken, selectedCompany, selectedUser, onReload}) {
  const theme = useTheme();

  const { setStatusMsg } = useServerData();

  let pw = generatePassword();

  const [fullName, setFullName] = useState(selectedUser.fullname ?? "New User");
  const [userName, setUserName] = useState(selectedUser.userName?.replace(selectedCompany?.nameShort + ".", "") ?? "");
  const [email, setEmail] = useState(selectedUser.email ?? "");
  const [phoneNumber, setPhoneNumber] = useState(selectedUser.phoneNumber ?? "");
  const [profilePicture, setProfilePicture] = useState(selectedUser.profilePictureBase64 ?? "");
  const [roles, setRoles] = useState(selectedUser.roleIds ?? []);
  const [password, setPassword] = useState(pw);
  const [confirmPassword, setConfirmPassword] = useState(pw);
  const [userToDelete, setUserToDelete] = useState(null);
  const [openPictureChooser, setOpenPictureChooser] = useState(false);

  const [availableRoles, setAvailableRoles] = useState([]);

  const [groups, setGroups] = useState(null);

  const [rawUserSettings, setRawUserSettings] = useState(null);

  const [userId, setUserId] = useState(selectedUser?.id);
  const [locationSharedConfig, setLocationSharedConfig] = useState(null);
  const [tableAccessConfigId, setTableAccessConfigId] = useState(selectedUser?.tableAccessConfigId);
  const [userSettings, setUserSettings] = useState(null);


  function addUser() {
    let fields = [];
    if(fullName == "") {
      fields.push("Full Name")
    }
    if(userName == "") {
      fields.push("Username")
    }
    if(fields.length > 0) {
      setStatusMsg({
        type: "Error",
        message: "Please fill in the following required fields: " + fields.join(", ")
      })
      return;
    }

    let user = {
      "fullname": fullName,
      "userName": selectedCompany.nameShort + "." + userName,
      "email": email,
      "phoneNumber": phoneNumber,
      "profilePictureBase64": profilePicture,
      "password": password,
      "confirmPassword": confirmPassword,
      "companyId": selectedCompany?.id,
      "roleIds": roles,
      "tableAccessConfigId": tableAccessConfigId
    }

    let url = GATEWAY_URL + ADMIN_URL + "/api/Users/AddUser";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(user)
    };

    callApiRawAsync(url, requestOptions, async (response) => {
      if(response.status < 300) {
        onReload();
      } else {
        setStatusMsg({
          type: "Error",
          message: await response.text()
        });
      }
    });
  }

  function editUser() {
    let fields = [];
    if(fullName == "") {
      fields.push("Full Name")
    }
    if(userName == "") {
      fields.push("Username")
    }
    if(fields.length > 0) {
      setStatusMsg({
        type: "Error",
        message: "Please fill in the following required fields: " + fields.join(", ")
      })
      return;
    }

    let user = {
      "userId": selectedUser?.id,
      "fullname": fullName,
      "userName": selectedCompany.nameShort + "." + userName,
      "email": email,
      "phoneNumber": phoneNumber,
      "profilePictureBase64": profilePicture,
      "companyId": selectedCompany?.id,
      "roleIds": roles,
      "tableAccessConfigId": tableAccessConfigId
    }

    console.log(user)


    let url = GATEWAY_URL + ADMIN_URL + "/api/Users/EditUser";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(user)
    };

    callApiRawAsync(url, requestOptions, async (response) => {
      if(response.status < 300) {
        onReload();
      } else {
        setStatusMsg({
        type: "Error",
        message: await response.text()
      });
      }
    });
  }

  function removeUser() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/Users/RemoveUser";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
      body: '"' + selectedUser.userName + '"'
    };
    callApiTextAsync(url, requestOptions, (response) => {
      console.log(response)
      if(response == "User removed successfully") {
        setUserToDelete(null);
        onReload();
      }
    });
  }

  function fetchRoles() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/Auth/Roles";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    callApiRawAsync(url, requestOptions, async (response) => {
      if(response.status < 300) {
        let roles = await response.json();
        roles = roles?.filter((i) => i.normalizedName == "COMPANYADMIN");
        setAvailableRoles(roles);
      } else {
        console.log(response)
        setStatusMsg({
        type: "Error",
        message: await response.text()
      })
      }
    });
  }

  function fetchGroups() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/TableAccessConfigs";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    callApiAsync(url, requestOptions, (response) => {
      let newGroups = response.filter((i) => i.CompanyId == selectedUser.companyId || i.CompanyId == 0);
      setGroups(newGroups);
    });
  }
  
  function fetchUserSettings() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/UserSettings/" + selectedUser?.id;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    callApiAsync(url, requestOptions, (response) => {
      setRawUserSettings(response);
    });
  }

  useEffect(() => {
    setUserId(rawUserSettings?.UserId);
    setLocationSharedConfig(rawUserSettings?.LocationShareConfig);
    let newUsSettings = parseSaveJson(rawUserSettings?.ExtraConfigJson);
    setUserSettings(newUsSettings)
  }, [rawUserSettings]);

  useEffect(() => {
    fetchRoles();
    fetchGroups();
    fetchUserSettings();
  }, [selectedUser]);

  return(
    <>
      <div style={{display: "flex", flexDirection: "column", gap: 64}}>
        <div style={{display: "flex", justifyContent: "space-between", border: "1px solid #ccc", borderRadius: 6, paddingInline: 8, paddingBlock: 4}}>
          <Typography style={{alignContent: "center"}} fontSize={24} fontWeight={600} color={theme.palette.text.primary}>Edit User</Typography>
          <div style={{display: "flex", gap: 16}}>
            <IconButton onClick={() => setUserToDelete(selectedUser)}><DeleteForever style={{color: "red"}}/></IconButton>
            <IconButton onClick={() => {
              if(selectedUser.isNew) {
                addUser();
              } else {
                editUser();
              }
            }}><Save/></IconButton>
          </div>        
        </div>

        <Typography fontSize={24} fontWeight={600} color={theme.palette.text.primary} style={{alignSelf: "center"}}>{selectedUser?.fullname}</Typography>
        {profilePicture ?
          <ButtonBase style={{backgroundImage: `url(${profilePicture})`, backgroundSize: "150px", borderRadius: "100vh", height: "150px", width: "150px", alignSelf: "center"}} onClick={() => setOpenPictureChooser(true)}>
            <div style={{backgroundColor: "#fff8", width: "36px", height: "36px", borderRadius: "100vh", alignContent: "center"}}>
              <AddAPhoto/>
            </div>
          </ButtonBase>
          :
          <ButtonBase style={{backgroundColor: "#ddd", borderRadius: "100vh", height: "150px", width: "150px", alignSelf: "center"}} onClick={() => setOpenPictureChooser(true)}>
            <AddAPhoto/>
          </ButtonBase>
        }
        
        <div style={{display: "flex", flexDirection: "column", gap: 16, width: "50%", alignSelf: "center", overflowY: "scroll", paddingBlock: 8}}>
          <TextField size="small" label="User ID" disabled value={selectedUser?.id}></TextField>
          <TextField size="small" label="Full Name" required value={fullName} onChange={(e) => setFullName(e.target.value)}></TextField>
          <FormControl variant="outlined">
            <InputLabel required htmlFor="outlined-adornment-password">Username</InputLabel>
            <OutlinedInput
              size="small"
              label="Username"
              id="outlined-adornment-weight"
              required
              value={userName}
              startAdornment={<InputAdornment position="start">{selectedCompany.nameShort}.</InputAdornment>}
              onChange={(e) => setUserName(e.target.value)}
            />
          </FormControl>
          <TextField size="small" label="Email" value={email} onChange={(e) => setEmail(e.target.value)}></TextField>
          <TextField size="small" label="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}></TextField>
          {selectedUser.isNew == true ? (
            <>
              <TextField size="small" label="Password" required value={password} onChange={(e) => setPassword(e.target.value)}></TextField>
              <TextField size="small" label="Confirm Password" required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></TextField>
            </>
          ) : null}

          <Autocomplete
            fullWidth
            size="small"
            value={availableRoles?.map((role) => ({id: role.id, label: role?.name ?? "Unknown"}))?.find((i) => roles?.includes(i?.id) ) ?? null}
            options={availableRoles?.map((role) => ({id: role.id, label: role?.name ?? "Unknown"})) ?? []}
            renderInput={(params) => <TextField {...params} label="Roles" />}
            isOptionEqualToValue={(option, value) => option.id == value.id}
            onChange={(option, value) => {
              setRoles(value != null ? [value.id] : [])
            }}
          />

          <Autocomplete
            fullWidth
            size="small"
            value={groups?.map((group) => ({id: group._id.$oid, label: group.GroupName ?? "Unknown"}))?.find((i) => tableAccessConfigId == i?.id) ?? null}
            options={groups?.map((group) => ({id: group._id.$oid, label: group.GroupName ?? "Unknown"})) ?? []}
            renderInput={(params) => <TextField {...params} label="Group" />}
            onChange={(option, value) => {
              setTableAccessConfigId(value?.id ?? "")
            }}
          />

        </div>
      </div>

      <ProfilePictureChooser
        open={openPictureChooser}
        onSubmit={(base64) => {
          setOpenPictureChooser(false);
          setProfilePicture(base64);
        }}
      />

      <Dialog open={userToDelete != null}>
        <DialogTitle>
          <div style={{display: "flex", gap: 8}}>
            <InfoOutlined style={{alignSelf: "center"}} color='error'/>
            <Typography fontSize={20}>Delete User</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          Do you want to delete "{userToDelete?.userName}"?
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "grey", width: "115px" }} onClick={() => setUserToDelete(null)}>
            Cancel
          </Button>
          <TimedButton
            style={{ width: "115px" }}
            color={"error"}
            variant="contained"
            onClick={() => { removeUser(userToDelete) }}
            autoFocus
            timeout={10}
          >
            Delete
          </TimedButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

function CompanyPage({bearerToken, onlineContainers, selectedCompany, onReload, users, setSelectedUser, groups, setSelectedGroup}) {
  const theme = useTheme();

  const { cfgs, styles, setStatusMsg } = useServerData();

  const [companyName, setCompanyName] = useState(selectedCompany.name ?? "");
  const [companyKey, setCompanyKey] = useState(selectedCompany?.companyKey ?? ObjectID());
  const [nameShort, setNameShort] = useState(selectedCompany.nameShort ?? "");

  const [logo, setLogo] = useState(selectedCompany.companyLogo ?? "");

  const [street, setStreet] = useState(selectedCompany.street ?? "");
  const [houseNumber, setHouseNumber] = useState(selectedCompany.houseNumber ?? "");
  const [postalCode, setPostalCode] = useState(selectedCompany.postalCode ?? "");
  const [city, setCity] = useState(selectedCompany.city ?? "");
  const [federalState, setFederalState] = useState(selectedCompany.federalState ?? "");
  const [state, setState] = useState(selectedCompany.state ?? "");
  const [phoneNumber, setPhoneNumber] = useState(selectedCompany.phoneNumber ?? "");
  const [email, setEmail] = useState(selectedCompany.email ?? "");
  const [vat, setVat] = useState(selectedCompany.vat ?? "");
  const [taxNumber, setTaxNumber] = useState(selectedCompany.taxNumber ?? "");
  const [bic, setBic] = useState(selectedCompany.bic ?? "");
  const [companyPackage, setPackage] = useState(selectedCompany.package ?? "");
  const [iban, setIban] = useState(selectedCompany.iban ?? "");
  const [website, setWebsite] = useState(selectedCompany.website ?? "");
  const [comment, setComment] = useState(selectedCompany.comment ?? "");

  const [containerState, setContainerState] = useState(selectedCompany.containerState ?? "");

  const [allowedTableIds, setAllowedTableIds] = useState(selectedCompany.allowedTableIds ?? []);
  const [allowedStyleHeaderIds, setAllowedStyleHeaderIds] = useState(selectedCompany.allowedStyleHeaderIds ?? []);

  const [companyToDelete, setCompanyToDelete] = useState(null);


  const [actionType, setActionType] = useState(null);
  const [selectedId, setSeletedId] = useState(null);

  const [conActionLoading, setConActionLoading] = useState(false);


  function changeEntry() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/Companies/" + selectedCompany.id + "/" + actionType + "/" + selectedId?.id;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: actionType.includes("assign") ? "POST" : "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };

    callApiAsync(url, requestOptions, async (response) => {
      setActionType(null);
      setSeletedId(null);

      if(response.status) {
        let msg = await response.text();
        setStatusMsg({
          type: "Error",
          message: msg
        });
      } else {
        setStatusMsg({
          type: "Error",
          message: response?.message
        });
      }
    });
  }

  function companyPOSTPUT() {
    let fields = [];
    if(companyName == "") {
      fields.push("Company Name")
    }
    if(nameShort == "") {
      fields.push("Name Short")
    }

    if(street == "") {
      fields.push("Street")
    }
    if(houseNumber == "") {
      fields.push("House Number")
    }
    if(postalCode == "") {
      fields.push("Postal Code")
    }
    if(city == "") {
      fields.push("City")
    }
    if(federalState == "") {
      fields.push("Federal State")
    }
    if(state == "") {
      fields.push("State")
    }
    if(phoneNumber == "") {
      fields.push("Phone Number")
    }
    if(email == "") {
      fields.push("Email")
    }

    if(fields.length > 0) {
      setStatusMsg({
        type: "Error",
        message: "Please fill in the following required fields: " + fields.join(", ")
      })
      return;
    }

    let company = {
      "name": companyName,
      "companyKey": companyKey,
      "nameShort": nameShort,
      "companyLogo": logo,
      "street": street,
      "houseNumber": houseNumber,
      "postalCode": postalCode,
      "city": city,
      "federalState": federalState,
      "state": state,
      "phoneNumber": phoneNumber,
      "email": email,
      "vat": vat,
      "taxNumber": taxNumber,
      "bic": bic,
      "package": companyPackage,
      "iban": iban,
      "website": website,
      "comment": comment,
      "allowedTableIds": allowedTableIds,
      "allowedStyleHeaderIds": allowedStyleHeaderIds,
      "ContainerState": containerState
    }

    let url = GATEWAY_URL + ADMIN_URL + "/api/Companies" + (selectedCompany.isNew ? "" : ("/" + selectedCompany.id));
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: selectedCompany.isNew ? "POST" : "PUT",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(company)
    };

    callApiRawAsync(url, requestOptions, async (response) => {
      if(response.status < 300) {
        onReload();
      } else {
        setStatusMsg({
          type: "Error",
          message: await response.text()
        })
      }
    });
  }

  function companyContainerStatePUT(state) {
    console.log(selectedCompany)

    let url = GATEWAY_URL + ADMIN_URL + "/api/Companies/ContainerState/" + selectedCompany.id + "?newState=" + state;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow"
    };

    callApiRawAsync(url, requestOptions, async (response) => {
      if(response.status < 300) {
        //onReload();
      } else {
        setStatusMsg({
          type: "Error",
          message: await response.text()
        })
      }
    });
  }

  function companyDELETE() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/Companies/" + selectedCompany?.id;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };

    callApiRawAsync(url, requestOptions, (response) => {
      console.log(response)
      if(response.status < 300) {
        setCompanyToDelete(null);
        onReload();
      }
    });
  }


  function startCompany() {
    setContainerState("STARTING");
    companyContainerStatePUT("STARTING");
  }

  function stopCompany() {
    setContainerState("STOPING");
    companyContainerStatePUT("STOPING");
  }

  //Update Container State for current Company
  useEffect(() => {
    const fetchData = async () => {
      let url = GATEWAY_URL + ADMIN_URL + "/api/Companies";
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + bearerToken);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      callApiAsync(url, requestOptions, (response) => {
        let updated = response.find((i) => i.id == selectedCompany.id);
        if(updated) {
          setContainerState(updated.containerState);
        }
      });
    };

    const intervalId = setInterval(fetchData, 2000);

    return () => clearInterval(intervalId);
  }, []);

  let onlineState = onlineContainers.find((i) => i.id == selectedCompany.id);
  let online = onlineState?.isOnline ?? false;

  console.log(containerState, containerState == "STARTED" || containerState == "STARTING")

  let msgs = {
    "STARTING": "Container starting...",
    "STARTED": "Container Online",
    "STOPING": "Container stopping...",
    "STOPPED": "Container Offline"
  }

  return(
    <>
      <div style={{display: "flex", justifyContent: "space-between", gap: 64, border: "1px solid #ccc", borderRadius: 6, paddingInline: 8, paddingBlock: 4}}>
        <Typography style={{alignContent: "center"}} fontSize={24} fontWeight={600} color={theme.palette.text.primary}>Edit Company</Typography>
        <div style={{display: "flex", gap: 16}}>

          {
            !selectedCompany.isNew ?
            containerState == "NEW" ? 
              <div style={{display: "flex", gap: 8}}>
                <Typography style={{alignSelf: "center"}}>Creating container...</Typography>
                <CircularProgress size={"17px"} style={{alignSelf: "center"}}/>
              </div>
              :
              <>
                <Link style={{alignContent: "center"}} href={window.location.origin + "/" + selectedCompany.companyKey + "/swagger/index.html"} underline="none" target="_blank">Company API Link</Link>
                <Divider orientation="vertical" variant='middle' flexItem/>
                <Typography style={{alignContent: "center"}}>{msgs[containerState]}</Typography>
                <div style={{position: "relative"}}>
                  <Tooltip title={containerState == "STARTED" ? "Stop Container": "Start Container"}>
                    <Switch
                      checked={containerState == "STARTED" || containerState == "STARTING"}
                      disabled={!(containerState == "STARTED" || containerState == "STOPPED")}
                      onChange={(e) => e.target.checked == true ? startCompany() : stopCompany()}
                    />
                  </Tooltip>
                  {containerState == "STARTING" ? <CircularProgress size={"17px"} style={{position: "absolute", top: "10.5px", right: "10.5px"}}/> : null}
                  {containerState == "STOPING" ? <CircularProgress size={"17px"} style={{position: "absolute", top: "10.5px", left: "10.5px"}}/> : null}
                </div>
              </>
            :
            null
          }

          <Divider orientation="vertical" variant='middle' flexItem/>

          <IconButton onClick={() => setCompanyToDelete(selectedCompany)}><DeleteForever style={{color: "red"}}/></IconButton>
          <IconButton onClick={() => companyPOSTPUT()}><Save/></IconButton>
        </div>        
      </div>

      <div style={{display: "flex", height: "calc(100vh - 230px)", overflow: "hidden"}}>
        <div style={{display: "flex", flexDirection: "column", flex: 1, gap: 16, padding: 8}}>
          <Typography fontSize={24} fontWeight={600} color={theme.palette.text.primary} style={{alignSelf: "center"}}>{selectedCompany?.name}</Typography>

          <div style={{display: "flex", flexDirection: "column", gap: 16, width: "80%", alignSelf: "center", overflow: "scroll", paddingBlock: 10}}>
            <TextField disabled size="small" label="Company Key" required value={companyKey} onChange={(e) => setCompanyKey(e.target.value)}></TextField>
            <TextField size="small" label="Company Name" required value={companyName} onChange={(e) => setCompanyName(e.target.value)}></TextField>
            <TextField disabled={!selectedCompany.isNew} size="small" label="Name Short" required value={nameShort} placeholder={"dieings, wiv, ..."} onChange={(e) => setNameShort(e.target.value)}></TextField>

            <Autocomplete
              fullWidth
              multiple
              size="small"
              value={cfgs?.map((table) => ({id: table.id, label: table?.tableName ?? "Unknown"}))?.filter((i) => allowedTableIds?.includes(i?.id) ) ?? []}
              options={cfgs?.map((table) => ({id: table.id, label: table?.tableName ?? "Unknown"})) ?? []}
              renderInput={(params) => <TextField {...params} label="Assigned Tables" />}
              isOptionEqualToValue={(option, value) => option.id == value.id}
              onChange={(option, value) => {
                setAllowedTableIds(value.map((i) => i.id))
              }}
            />

            <Autocomplete
              fullWidth
              multiple
              size="small"
              value={styles?.map((style) => ({id: style.id, label: style?.styleName ?? "Unknown"}))?.filter((i) => allowedStyleHeaderIds?.includes(i?.id) ) ?? []}
              options={styles?.map((style) => ({id: style.id, label: style?.styleName ?? "Unknown"})) ?? []}
              renderInput={(params) => <TextField {...params} label="Assigned Styles" />}
              isOptionEqualToValue={(option, value) => option.id == value.id}
              onChange={(option, value) => {
                setAllowedStyleHeaderIds(value.map((i) => i.id))
              }}
            />

            <TextField size="small" required label="Street" value={street} onChange={(e) => setStreet(e.target.value)}></TextField>
            <TextField size="small" required label="House Number" value={houseNumber} onChange={(e) => setHouseNumber(e.target.value)}></TextField>
            <TextField size="small" required label="Postal Code" value={postalCode} onChange={(e) => setPostalCode(e.target.value)}></TextField>
            <TextField size="small" required label="City" value={city} onChange={(e) => setCity(e.target.value)}></TextField>
            <TextField size="small" required label="Federal State" value={federalState} onChange={(e) => setFederalState(e.target.value)}></TextField>
            <TextField size="small" required label="State" value={state} onChange={(e) => setState(e.target.value)}></TextField>
            <TextField size="small" required label="Phonenumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}></TextField>
            <TextField size="small" required label="Email" value={email} onChange={(e) => setEmail(e.target.value)}></TextField>
            <TextField size="small" label="Vat" value={vat} onChange={(e) => setVat(e.target.value)}></TextField>
            <TextField size="small" label="Tax Number" value={taxNumber} onChange={(e) => setTaxNumber(e.target.value)}></TextField>
            <TextField size="small" label="BIC" value={bic} onChange={(e) => setBic(e.target.value)}></TextField>
            <TextField size="small" label="Package" value={companyPackage} onChange={(e) => setPackage(e.target.value)}></TextField>
            <TextField size="small" label="IBAN" value={iban} onChange={(e) => setIban(e.target.value)}></TextField>
            <TextField size="small" label="Website" value={website} onChange={(e) => setWebsite(e.target.value)}></TextField>
            <TextField size="small" label="Comment" value={comment} onChange={(e) => setComment(e.target.value)}></TextField>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: 16, flex: 2.5 }}>

          <div style={{ padding: 8, height: "30%", border: "1px solid #ccc", borderRadius: 6 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography fontSize={24} fontWeight={600} color={theme.palette.text.primary}>Groups</Typography>
              <IconButton onClick={() => setSelectedGroup({GroupName: "New Group", CompanyId: selectedCompany?.id, isNew: true})}><Add/></IconButton>
            </div>
            <div style={{display: "flex", flexWrap: "wrap", overflowY: "scroll", gap: 16, justifyContent: "flex-start", maxHeight: "calc(100% - 40px)"}}>
              {groups?.sort((a, b) => ('' + a.GroupName).localeCompare(b.GroupName)).map((group) => {
                return(
                  <ButtonBase component={"div"} style={{height: 150, width: 400, borderRadius: 8, backgroundColor: "#ddd"}}  onClick={() => setSelectedGroup(group)}>
                    <Typography fontSize={20} fontWeight={600} color={theme.palette.text.primary} style={{position: "absolute", top: 8, left: 12}}>{group?.GroupName}</Typography>
                    <Typography fontSize={16} color={theme.palette.text.primary} style={{position: "absolute", top: 36, left: 12}}>{group?.Description}</Typography>
                  </ButtonBase>
                );
              })}
            </div>
          </div>
          
          <div style={{ padding: 8, height: "70%", border: "1px solid #ccc", borderRadius: 6 }}>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
              <Typography fontSize={24} fontWeight={600} color={theme.palette.text.primary}>Users</Typography>
              <IconButton onClick={() => setSelectedUser({fullName: "New User", companyId: selectedCompany?.id, isNew: true})}><Add/></IconButton>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", overflowY: "scroll", gap: 16, justifyContent: "flex-start", maxHeight: "calc(100% - 70px)" }}>
              {users?.sort((a, b) => ('' + a.fullname).localeCompare(b.fullname)).map((user) => {
                if(user.companyName != selectedCompany?.name) return;

                return(
                  <ButtonBase component={"div"} style={{height: 150, width: 400, borderRadius: 8, backgroundColor: "#ddd"}}  onClick={() => setSelectedUser(user)}>
                    <Typography fontSize={20} fontWeight={600} color={theme.palette.text.primary} style={{position: "absolute", top: 8, left: 12}}>{user?.fullname}</Typography>
                    <Typography fontSize={16} color={theme.palette.text.primary} style={{position: "absolute", top: 36, left: 12}}>{user?.userName}</Typography>
                    <div style={{position: "absolute", top: 8, right: 12}}>
                      <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "baseline", gap: 4}}>
                        <div style={{backgroundColor: user?.connectionId ? "#090" : "#999", borderRadius: "100vh", height: 12, width: 12, verticalAlign: "center"}}/>
                        <Typography fontSize={14} color={theme.palette.text.primary}>{user?.connectionId ? "Online" : "Offline"}</Typography>
                      </div>
                    </div>

                    <div style={{position: "absolute", bottom: 8, right: 12, textAlign: "right"}}>
                      <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "baseline", gap: 4}}>
                        <Typography fontSize={14} color={theme.palette.text.primary}>{user.email}</Typography>
                      </div>
                    </div>
                  </ButtonBase>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Dialog open={companyToDelete != null}>
        <DialogTitle>
          <div style={{display: "flex", gap: 8}}>
            <InfoOutlined style={{alignSelf: "center"}} color='error'/>
            <Typography fontSize={20}>Delete Company</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
        Do you want to delete "{companyToDelete?.name}"?
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "grey", width: "115px" }} onClick={() => setCompanyToDelete(null)}>
            Cancel
          </Button>
          <TimedButton
            style={{ width: "115px" }}
            color={"error"}
            variant="contained"
            onClick={() => { companyDELETE(companyToDelete) }}
            autoFocus
            timeout={10}
          >
            Delete
          </TimedButton>
        </DialogActions>
      </Dialog>

      <Dialog open={actionType != null} maxWidth="sm" fullWidth>
        <DialogTitle>
          {actionType}
        </DialogTitle>
        <DialogContent style={{padding: 16}}>
          <Autocomplete
            id="free-solo-demo"
            fullWidth
            size="small"
            options={actionType?.includes("table") ? cfgs?.map((table) => ({id: table.id, label: table?.tableName ?? "Unknown"})) : styles?.map((style) => ({id: style.id, label: style?.styleName ?? "Unknown"}))}
            renderInput={(params) => <TextField {...params} label={actionType?.includes("table") ? "Tables" : "Styles"}/>}
            onChange={(option, value) => {
              setSeletedId(value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setActionType(null)}>
            Cancel
          </Button>
          <Button onClick={changeEntry}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function CompanyUserConfiguration(props) {
  const theme = useTheme();

  const { BASE_URL } = useServerData();

  const [companies, setCompanies] = useState([]);
  const [onlineContainers, setOnlineContainers] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState(null);

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  
  function fetchCompanies() {
    let url = BASE_URL + "/api/Companies";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    callApiAsync(url, requestOptions, (response) => {
      setCompanies(response);
    });
  }

  function fetchGroups() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/TableAccessConfigs";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    callApiAsync(url, requestOptions, (response) => {
      let newGroups = response.filter((i) => i.CompanyId == selectedCompany?.id);
      setGroups(newGroups);
    });
  }

  function fetchUsers() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/Users/GetUsers";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    callApiAsync(url, requestOptions, (response) => {
      setUsers(response);
    });
  }

  function checkContainerState() {
    let containers = [];
    companies.map((company, idx) => {

      console.log(company)

      setTimeout(() => {
        let url = "/" + company?.companyKey + "/api/Auth/healthCheck";
        callApiRawAsync(url, { signal: AbortSignal.timeout(5000) }, (response) => {
          if(response?.status == 200) {
            containers.push({id: company.id, isOnline: true});
            setOnlineContainers([...containers]);
          } else {
            containers.push({id: company.id, isOnline: false});
            setOnlineContainers([...containers]);
          }
        });
      }, idx + 500);
    });
  }

  function siteContent() {
    if(selectedGroup != null) {
      return(
        <GroupPage
          selectedCompany={selectedCompany}
          selectedGroup={selectedGroup}
          users={users}
          bearerToken={props.bearerToken}
          onReload={() => {
            console.log("reload")
            setSelectedGroup(null);
            fetchGroups();
          }}
        />
      )
    }
    if(selectedUser != null) {
      return(
        <UserPage
          selectedCompany={selectedCompany}
          selectedUser={selectedUser}
          bearerToken={props.bearerToken}
          onReload={() => {
            console.log("reload")
            setSelectedUser(null);
            fetchUsers();
          }}
        />
      )
    }
    if(selectedCompany != null) {
      return(
        <CompanyPage
          selectedCompany={selectedCompany}
          onlineContainers={onlineContainers}
          bearerToken={props.bearerToken}
          groups={groups}
          users={users}
          setSelectedGroup={setSelectedGroup}
          setSelectedUser={setSelectedUser}
          onReload={() => {
            fetchCompanies();
            setSelectedCompany(null);
          }}
        />
      )
    } else {
      return(
        <>
          <div style={{display: "flex", justifyContent: "space-between", border: "1px solid #ccc", borderRadius: 6, paddingInline: 8, paddingBlock: 4}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <Typography style={{alignContent: "center"}} fontSize={24} fontWeight={600} color={theme.palette.text.primary}>Companies</Typography>
              <IconButton onClick={() => {
                fetchCompanies();
                fetchUsers();
              }}>
                <Refresh/>
              </IconButton>
            </div>
            <IconButton onClick={() => setSelectedCompany({name: "New Company", containerState: "NEW", isNew: true})}><Add/></IconButton>
          </div>
          <div style={{display: "flex", flexWrap: "wrap", height: "25vh", overflowY: "scroll", gap: 16, justifyContent: "flex-start"}}>
            {companies?.map((company) => {
              let companyUsers = users.filter((user) => user.companyName == company.name);
              let companyUsersOnline = users.filter((user) => user.companyName == company.name && user.connectionId != "");
              let onlineState = onlineContainers.find((i) => i.id == company.id);
              let online = onlineState?.isOnline ?? false;

              return(
                <ButtonBase component={"div"} style={{height: 150, width: 400, borderRadius: 8, backgroundColor: "#ddd"}} onClick={() => setSelectedCompany(company)}>
                  <Typography fontSize={20} fontWeight={600} color={theme.palette.text.primary} style={{position: "absolute", top: 8, left: 12}}>ID {company?.id} - {company?.name}</Typography>
                  <Typography fontSize={16} color={theme.palette.text.primary} style={{position: "absolute", top: 36, left: 12}}>{company?.url}</Typography>
                  <div style={{position: "absolute", bottom: 8, right: 12, textAlign: "right"}}>
                    <Typography fontSize={14} color={theme.palette.text.primary}>{companyUsersOnline?.length ?? 0} / {companyUsers?.length ?? 0} Online</Typography>
                    <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "baseline", gap: 4}}>
                      <div style={{backgroundColor: onlineState?.isOnline != undefined ? online ? "#090" : "red" : "#999", borderRadius: "100vh", height: 12, width: 12, verticalAlign: "center"}}/>
                      <Typography fontSize={14} color={theme.palette.text.primary}>{onlineState?.isOnline != undefined ? online ? "Container Online" : "Container Offline" : "Checking online state..."}</Typography>
                    </div>
                  </div>
                </ButtonBase>
              );
            })}
          </div>
        </>
      )
    }
  }
  

  let links = useMemo(() => {
    let items = [];
    items.push(<Link underline="hover" style={{cursor: "pointer"}} onClick={() => {
      setSelectedCompany(null);
      setSelectedGroup(null);
      setSelectedUser(null);
    }}>Companies</Link>);
    if(selectedCompany) {
      items.push(<Link underline="hover" style={{cursor: "pointer"}} onClick={() => {
        setSelectedGroup(null);
        setSelectedUser(null);
      }}>{selectedCompany?.name}</Link>);
    }
    if(selectedGroup) {
      items.push(<Link underline="hover" style={{cursor: "pointer"}}>{selectedGroup?.GroupName}</Link>);
    }
    if(selectedUser) {
      items.push(<Link underline="hover" style={{cursor: "pointer"}}>{selectedUser?.fullname}</Link>);
    }
    return(items);
  }, [selectedCompany, selectedGroup, selectedUser]);

  const { lastNotification } = useSignalR();
  useEffect(() => {
    if(lastNotification?.type == "SetAsOnline" || lastNotification?.type == "SetAsOffline") {
      fetchUsers();
    }
  }, [lastNotification]);

  useEffect(() => {
    checkContainerState();
  }, [companies]);

  useEffect(() => {
    fetchCompanies();
    fetchGroups();
    fetchUsers();
  }, [selectedCompany]);

  return (
    <div style={{ display: "flex", backgroundColor: theme.palette.background.default, flexDirection: "column", width: "100%", height: "calc(100vh)", overflow: "hidden" }}>
      <div style={{margin: "30px", display: "flex", flexDirection: "column", gap: "20px"}}>
        <Typography fontSize={30} fontWeight={600} color={theme.palette.text.primary}>Company Overview</Typography>
        <Stack spacing={2}>
          <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
            {links}
          </Breadcrumbs>
        </Stack>
        {siteContent()}
      </div>
    </div>
  );
}

export default CompanyUserConfiguration;