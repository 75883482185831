import { useTheme } from '@emotion/react';
import { AttachFile } from '@mui/icons-material';
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { memo, useEffect, useMemo, useState } from 'react';
import { MuiFileInput } from 'mui-file-input'
import {v4 as uuidv4} from 'uuid';
import { convertToGeoJSON, ObjectID } from '../../js/helper';

function ImportDialog({ importDlgOpen, setImportDlgOpen, selectedCfg, onEntryCreate }) {
  const theme = useTheme();

  const [importedFile, setImportedFile] = useState(null);
  const [entries, setEntries] = useState(null);
  const [mappingConfig, setMappingConfig] = useState({});

  const [loading, setLoading] = useState(false);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [autoMapping, _] = useState({
    "lat": "lat",
    "latitude": "lat",

    "lon": "lon",
    "long": "lon",
    "longitude": "lon",

    "name": "name",

    "shape_area": "area_size"
  });


  function handleMappingUpdate(key, value) {
    let newMapping = {...mappingConfig};
    newMapping[key] = value;
    setMappingConfig(newMapping);
  }

  function handleEntryCreate() {
    let entriesToCreate = rowsEntry.filter((entry) => rowSelectionModel.includes(entry.id));
    onEntryCreate(entriesToCreate)
  }


  useEffect(() => {
    var reader = new FileReader();
    reader.onload = function() { 
      let parser = new DOMParser();
      let kmlDoc = parser.parseFromString(reader.result,"text/xml");
      
      const kmlNamespace = "http://www.opengis.net/kml/2.2";
      let placemarks = kmlDoc.getElementsByTagNameNS(kmlNamespace, "Placemark");

      // Initialize an array to store the extracted placemarks
      const placemarkData = [];

      // Iterate over all the placemarks
      for (let i = 0; i < placemarks.length; i++) {
        const placemark = placemarks[i];

        // Extract the ExtendedData SchemaData SimpleData fields
        const simpleDataElements = placemark.getElementsByTagNameNS(kmlNamespace, "SimpleData");
        const extendedData = {};
        for (let j = 0; j < simpleDataElements.length; j++) {
          const name = simpleDataElements[j].getAttribute("name");
          const value = simpleDataElements[j].textContent;
          extendedData[name] = value;
        }

        // Extract the coordinates
        const coordinatesElement = placemark.getElementsByTagNameNS(kmlNamespace, "coordinates")[0];
        const coordinates = coordinatesElement ? coordinatesElement.textContent.trim() : null;

        let coordsArr = coordinates.includes("\n") ? coordinates.split("\n"): coordinates.split(" ");
        const placemarkInner = placemark.outerHTML;

        // Add the extracted data to the array
        placemarkData.push({
          extendedData,
          coordsArr,
          placemarkInner
        });
      }
      setLoading(false);
      setEntries(placemarkData);
    }

    if(importedFile) {
      setLoading(true);
      reader.readAsText(importedFile);
    }

  }, [importedFile])

  useEffect(() => {
    if(!importDlgOpen) {
      setImportedFile(null);
      setEntries(null);
      setLoading(false);
    }
  }, [importDlgOpen])


  //File Data Table
  let columns = useMemo(() => {
    let columns = [];
    entries?.map((entry) => {
      Object.entries(entry.extendedData).map(([key, value]) => {
        let foundColumn = columns.find((col) => col.field == key);
        if(!foundColumn) {
          let newColumn = {
            field: key,
            type: "string",
            headerName: key,
            flex: 1,
            minWidth: 150,
            pinnable: false,
            groupable: false
          }
          columns.push(newColumn);
        }
      })
    });
    return(columns);
  }, [entries]);

  let rows = useMemo(() => (
    entries != null ? entries.map((entry, idx) => {
      var data = entry.extendedData;
      data["id"] = idx;
      return data;
    }) : []
  ), [entries]);

  let columnOptions = useMemo(() => {
    let options = columns.map((col) => { return({id: col.field, label: col.field}); })
    return(options);
  }, [columns])

  //Entry Data Table
  let columnsEntry = useMemo(() => {
    let innerColumns = [];

    if(selectedCfg) {
      for(let col of selectedCfg.columns) {
        
        let newColumn = {
          field: col.key,
          type: "string",
          headerName: col.columnName,
          flex: 1,
          minWidth: 200,
          valueGetter: (_, row) => { return(row[col.key]) },
          renderHeader: (_) => (
            <Autocomplete
              fullWidth
              style={{minWidth: 180, marginTop: "10px", marginBottom: "10px"}}
              renderInput={(params) => <TextField {...params} fullWidth size='small' label={col?.columnName}/>}
              options={columnOptions}
              onChange={(_, value) => handleMappingUpdate(col?.key, value?.id)}
            />
          ),
        }
        innerColumns.push(newColumn);
      }
    }
    return(innerColumns);
  }, [entries, mappingConfig, selectedCfg]);

  let rowsEntry = useMemo(() => {
    let mts = new Date().toISOString();
    let locationCol = selectedCfg?.columns?.find((i) => i.fieldType == "Location");

    return entries != null ? entries.map((entry, idx) => {
      let poi = {}
      Object.entries(entry.extendedData).map(([key, value]) => {
        let mapping = Object.entries(mappingConfig).find(([mappingKey, mappingValue]) => key == mappingValue);
        if(mapping) {
          poi[mapping[0]] = value;
        }
      })

      //Set splitted map elements
      if(!entry.placemarkInner.includes("<Point>")) {
        let vectorOverlayKey = "map";
        if(selectedCfg) {
          let mapCol = selectedCfg?.columns?.find((i) => i.fieldType == "Map");
          vectorOverlayKey = mapCol.key;
        }
        
        let splittedKml = '<?xml version="1.0" encoding="utf-8" ?><kml xmlns="http://www.opengis.net/kml/2.2"><Document id="root_doc">' + entry.placemarkInner + '</Document></kml>';
        
        //convert kml to geojson
        let geojson = convertToGeoJSON(splittedKml);
        poi[vectorOverlayKey] = JSON.stringify(geojson);
        poi["ModifiedAt"] = mts;
        poi["fieldsModifiedAt"] = {
          ...poi["fieldsModifiedAt"],
          [vectorOverlayKey]: mts
        }
      }

      //Set Poi Position
      let lat = 0;
      let lon = 0;
      let tmpLat = 0.0, tmpLon = 0.0;
      entry.coordsArr.map((coords) => {
        let innerLat = coords.trim().split(",")[1];
        let innerLon = coords.trim().split(",")[0];
        tmpLat += parseFloat(innerLat);
        tmpLon += parseFloat(innerLon);
      })
      lat = tmpLat / entry.coordsArr.length;
      lon = tmpLon / entry.coordsArr.length;


      poi._id = { $oid: ObjectID() };
      
      if(locationCol) {
        poi[locationCol.key] = {
          lat: lat,
          long: lon
        }
      }

      poi["id"] = poi._id.$oid;
      return poi;
    }) : []
  }, [entries, mappingConfig, selectedCfg]);

  return(
    <Dialog fullWidth maxWidth="xl" open={importDlgOpen} PaperProps={{ sx: { borderRadius: "16px", height: "90vh", maxHeight: "1000px" } }}>
      <DialogTitle>Daten Import</DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <div style={{display: "flex", flexDirection: "column", gap: "15px" }}>
          <MuiFileInput
            label="KML Datei"
            value={importedFile}
            onChange={(file) => setImportedFile(file)}
            style={{ cursor: "pointer", backgroundColor: theme.palette.background.default, marginTop: "10px" }}
            InputProps={{
              inputProps: {
                accept: '.kml'
              },
              style: {
                cursor: "pointer"
              },
              startAdornment: <AttachFile />
            }}
          />
          <Box style={{ height: "300px", border: "solid 1px #aaa", borderRadius: 4, overflow: "hidden", backgroundColor: theme.palette.background.default }}>
            <DataGridPremium
              loading={loading}
              rows={rows}
              columns={columns}
              pageSizeOptions={[5, 10, 25]}
              initialState={{
                density: "compact"
              }}
              style={{
                borderStyle: "none"
              }}
            />
          </Box>

          <Typography style={{fontSize: 20, fontWeight: "bold"}}>Einträge übernehmen</Typography>
          <Box style={{ height: "400px", border: "solid 1px #aaa", borderRadius: 4, overflow: "hidden", backgroundColor: theme.palette.background.default }}>
            <DataGridPremium
              loading={loading}
              columnHeaderHeight={90}
              headerFilterHeight={90}
              rows={rowsEntry}
              columns={columnsEntry}
              rowSelection
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              disableColumnSorting
              pageSizeOptions={[5, 10, 25]}
              initialState={{
                density: "compact"
              }}
              style={{
                borderStyle: "none"
              }}
            />
          </Box>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "0px 24px 20px 0px" }}>
        {true ? (
          <Box sx={{ m: 1, position: 'relative' }}>
          <Tooltip title={loading ? "Karte wird erstellt..." : ""} placement='top'>
            <span>
              <Button
                variant="contained"
                disabled={loading || rowSelectionModel.length == 0}
                onClick={() => {
                  handleEntryCreate()
                  setImportDlgOpen(false)
                }}>
                  {"Importieren"}
                </Button>
            </span>
          </Tooltip>
          {loading && (
            <Tooltip title={loading ? "Karte wird erstellt..." : ""} placement='top'>
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            </Tooltip>
          )}
        </Box>
        ) : null}

        {/*<Button
          variant='contained'
          disabled={rowSelectionModel.length == 0}
          onClick={() => {
            handleEntryCreate()
            setImportDlgOpen(false)
          }}
        >
          Erstellen
        </Button>*/}
        <Button style={{ color: "grey" }} onClick={() => setImportDlgOpen(false)}>Abbrechen</Button>
      </DialogActions>
    </Dialog>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (<Box sx={{ p: 3 }}>{children}</Box>)}
    </div>
  );
}

export default memo(ImportDialog)