import "./views/mainView/MainView";
import "./widgets/navBar/NavBarItem.css";
import LoginView from "./views/loginView/LoginView";
import MainView from "./views/mainView/MainView";
import { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { deDE } from '@mui/x-data-grid/locales';
import { callApiAsync } from "./js/helper";
import { ADMIN_URL, GATEWAY_URL } from "./js/defines";
import Providers from "./context";
import { LicenseInfo } from '@mui/x-license';
import SignalRProvider from "./providers/SignalRProvider";
import DataProvider from "./providers/DataProvider";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translations.json";
import Main from "./views/Main";

function App() {
  const [themeType, setThemeType] = useState("light");
  const theme = createTheme({
    palette: {
      mode: themeType == "dark" ? "dark" : "light",
      primary: {
        main: '#009900',
      },
      background: {
        default: themeType == "dark" ? "#333" : "#eee",
        paper: themeType == "dark" ? "#232323" : "#ddd"
      }
    }
  }, deDE);

  i18n.use(initReactI18next).init(translations);
  
  LicenseInfo.setLicenseKey('3d8e4376b989312ca4a07d558a7b65d3Tz05NTg3MSxFPTE3NTQ4MDg3NDgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');

  return(
    <Providers>
      <ThemeProvider theme={theme}>
        <Main/>
      </ThemeProvider>
    </Providers>
  );
}

export default App;