import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import default_config_layer from "../poiConfigs/default_config_layer.json";
import { useSignalR } from "./SignalRProvider";
import { ADMIN_URL, GATEWAY_URL } from "../js/defines";
import { callApiAsync, parseSaveJson } from "../js/helper";

const DataContext = createContext();

export default function DataProvider({userState, children}) {

  const [userId, setUserId] = useState(null);
  const [locationSharedConfig, setLocationSharedConfig] = useState(null);
  const [tableAccessConfigId, setTableAccessConfigId] = useState(null);
  const [userSettings, setUserSettings] = useState(null);

  const [serverSyncState, setServerSyncState] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [buddies, setBuddies] = useState([]);

  const [cfgs, setCfgsStatic] = useState(null); //Available Tabs
  const [activeCfgs, setActiveCfgs] = useState(userSettings?.activeCfgs ?? []); //Open Tabs
  useEffect(() => setActiveCfgs(userSettings?.activeCfgs ?? []), [userSettings?.activeCfgs]);

  const [selectedCfg, setSelectedCfg] = useState(userSettings?.selectedCfg ?? null); //Active Tab
  useEffect(() => setSelectedCfg(userSettings?.selectedCfg ?? null), [userSettings?.selectedCfg]);

  const [styles, setStyles] = useState([]); //Available Styles
  const [selectedStyles, setSelectedStyles] = useState({}); //Active Styles

  const [dataEntries, setDataEntries] = useState({}); //Open Poi Entries
  const [mapConfigs, setMapConfigs] = useState({}); //Map Config - Show Icon - Show Vector

  const [selectedPois, setSelectedPois] = useState({}); //Open Selected Poi Entries
  const [selectedBlobs, setSelectedBlobs] = useState({}); //Open Selected Poi Blobs
  const [selectedRows, setSelectedRows]= useState({}); //Open Selected Poi Rows
  const [showArchived, setShowArchived]= useState({}); //Open Selected Poi Filters
  const [selectedFilters, setSelectedFilters]= useState({}); //Open Selected Poi Filters

  const [layers, setLayers]= useState(userSettings?.mapLayers ?? default_config_layer); //Open Selected Poi Rows
  useEffect(() => setLayers(userSettings?.mapLayers != null && userSettings?.mapLayers.length > 0 ? userSettings?.mapLayers : default_config_layer), [userSettings?.mapLayers]);

  const [favLayers, setFavLayers]= useState(userSettings?.favLayers ?? []); //Open Selected Poi Rows
  useEffect(() => setFavLayers(userSettings?.favLayers ?? []), [userSettings?.favLayers]);

  const [enabledBaseLayer, setEnabledBaseLayer]= useState(userSettings?.enabledBaseLayer ?? "osm"); //Open Selected Poi Rows
  useEffect(() => setEnabledBaseLayer(userSettings?.enabledBaseLayer ?? "osm"), [userSettings?.enabledBaseLayer]);

  const [enabledLayers, setEnabledLayers]= useState(userSettings?.enabledLayers ?? []); //Open Selected Poi Rows
  useEffect(() => setEnabledLayers(userSettings?.enabledLayers ?? []), [userSettings?.enabledLayers]);

  const [enabledFavLayer, setEnabledFavLayer]= useState(userSettings?.enabledFavLayer ?? ""); //Open Selected Poi Rows
  useEffect(() => setEnabledFavLayer(userSettings?.enabledFavLayer ?? ""), [userSettings?.enabledFavLayer]);

  const [statusMsg, setStatusMsg] = useState(null);

  const { lastNotification } = useSignalR();

  let BASE_URL = useMemo(() => {
    if(userState?.userState?.companyKey) {
      return GATEWAY_URL + "/" + userState?.userState?.companyKey;
    } else {
      return GATEWAY_URL + ADMIN_URL;
    }
  }, [userState]);

  async function fetchTables() {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + userState.token);
      myHeaders.append("Content-Type", "application/json")
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      let url = BASE_URL + "/api/Tables";
      let resp = await fetch(url, requestOptions)
      return resp.json();
    } catch (e) {
      setStatusMsg({
        type: "Error",
        message: e
      });
    }
  }

  async function setTables() {
    let resp = await fetchTables();
    setCfgs(resp);
    console.log("REFRESHED TABLES", resp)
  }

  async function fetchBuddies() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/Users/GetUsers";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userState.token);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    callApiAsync(url, requestOptions, (response) => {
      //let newBuddies = response.filter((i) => i.userName != props.userState.username);
      let newBuddies = response;
      setBuddies(newBuddies);
    });
  }

  async function fetchStyles() {
    let url = BASE_URL + "/api/StyleHeaders"; ///TODO
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userState.token);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    callApiAsync(url, requestOptions, (response) => {
      console.log("STYLES", response);
      setStyles(response);
    });
  }


  //TableRows- GET
  async function getData(innerDataEntries, cfg, newShowArchived) {
    let innerShowArchived = showArchived;
    if(newShowArchived) innerShowArchived = newShowArchived;

    var newDataEntries = {...innerDataEntries};
    if(newDataEntries == null) {
      newDataEntries = {}
    }

    if(cfg == null) return;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userState.token);
    let requestOptions = {
      method: "GET",
      headers: myHeaders
    };

    //Build url
    let url = BASE_URL + "/api/Entries/ById/" + cfg.id;

    //Add archived filter
    let jsonPath = '$[?(';
    if(!innerShowArchived[cfg.uniqueKey] == true) {
      let archiveCol = cfg.columns.find((i) => i.fieldType == "Archive");  
      if(archiveCol != null) {
        let archivedFilter = '!@.' + archiveCol.key + ' == true';
        jsonPath += archivedFilter;
      }
    }

    //Add parent filter
    if(cfg.parentUniqueKey) {
      let parentIds = selectedRows[cfg.parentUniqueKey];  
      //If no parent selected remove data
      if(parentIds == null || (parentIds != null && parentIds?.length == 0)) {
        if(newDataEntries == null) {
          newDataEntries = {}
        }
        newDataEntries[cfg.uniqueKey] = [];
        return newDataEntries;
      }
      if(parentIds?.length > 0) {
        parentIds.forEach((id, idx) => {
          if(idx != parentIds.length -1 || jsonPath.includes("!@.")) {
            jsonPath += ' || ';
          }
          jsonPath += '@.parentOids.' + cfg.parentUniqueKey + '[?(@=="' + id + '")]';
        });
      }      
    }
    jsonPath += ')]';
    if(jsonPath.includes("@.")) url += '?jsonPath=' + encodeURIComponent(jsonPath);

    console.log("TRY GETTING DATA FOR", url)

    setServerSyncState(-2);

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
          console.log(`HTTP error! status: ${response.status}`)
          setServerSyncState(0);

          if(response.status == 404) {
            setStatusMsg({
              type: "Error",
              message: "404 - Not found: " + url
            });
            return newDataEntries;
          }

          let resp = await response.json();
          setStatusMsg({
            type: "Error",
            message: resp.error
          });
          return newDataEntries;
      }
      
      let data = await response.json();

      ///TODO - Format Data for debug - Should be removed backend side
      data?.data?.map((entry) => {
        delete entry.fieldsModifiedAt;
      })
 
      newDataEntries[cfg.uniqueKey] = data?.data;

      //Recursive
      let dynamicListCol = cfg?.columns?.find((i) => i.fieldType == "DynamicList");

      if(dynamicListCol) {
        let dynamicListJson = parseSaveJson(dynamicListCol?.configurationsJson);
        let dynamicCfg = cfgs.find((i) => i.id == dynamicListJson.tableId);
        if(dynamicCfg) {
          let dynamicItems = await getData(newDataEntries, dynamicCfg);
          if(dynamicItems) {
            newDataEntries = dynamicItems;
          }
        }
      }

      setServerSyncState(0);
      return newDataEntries;

    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  async function getTableRows(cfg, newShowArchived) {
    let newDataEntries = await getData(dataEntries, cfg, newShowArchived);
    //console.log("newDataEntries", newDataEntries);
    setDataEntries(newDataEntries);
  }


  //TableRows - POST
  async function postTableRows() {}

  //TableRows - PATCH
  async function putTableRows() {}






  //User Settings Calls
  const userSettingsTimerRef = useRef();

  function editUserSetting(key, value) {
    userSettings[key] = value;
    setUserSettings(userSettings);
  }

  function saveUserSetting(key, value) {
    let newUserSettings = {...userSettings};
    newUserSettings[key] = value;
    setUserSettings(newUserSettings);
  }

  function saveCompleteUserSettings(newUserSettings) {
    setUserSettings({...newUserSettings});
  }

  useEffect(() => {
    setUserId(userState?.userState?.userSettings?.userId);
    setLocationSharedConfig(userState?.userState?.userSettings?.locationSharedConfig);
    setTableAccessConfigId(userState?.userState?.userSettings?.tableAccessConfigId);
    let newUsSettings = parseSaveJson(userState?.userState?.userSettings?.extraConfigJson);
    setUserSettings(newUsSettings)
  }, [userState?.userState?.userSettings]);

  //POST UserSettings to Server
  useEffect(() => {
    clearTimeout(userSettingsTimerRef.current);
    userSettingsTimerRef.current = setTimeout(() => {
      console.log("REQUEST", userSettings);

      let usSettings = {
        userId: userId,
        tableAccessConfigId: tableAccessConfigId,
        locationShareConfig: locationSharedConfig,
        extraConfigJson: JSON.stringify(userSettings)
      }

      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + userState.token);
      myHeaders.append("Content-Type", "application/json");

      if(usSettings?.userId) {
        let requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(usSettings),
          redirect: "follow"
        }
        callApiAsync(GATEWAY_URL + ADMIN_URL + "/api/UserSettings/" + usSettings?.userId, requestOptions, (response) => {
          console.log("put", response)
        });
      } else {
        usSettings.userId = userState?.userState?.id;
        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(usSettings),
          redirect: "follow"
        }
        callApiAsync(GATEWAY_URL + ADMIN_URL + "/api/UserSettings", requestOptions, (response) => {
          console.log("post", response)
        });
      }
    }, 2000);
  }, [userSettings])

  useEffect(() => {
    if(lastNotification?.type == "RefetchTables" || lastNotification?.type == "RefetchTable") {
      setTables();
    }

    if(lastNotification?.type == "RefetchTableRows") {
      let activeCfg = activeCfgs.find((i) => i.id == lastNotification?.value?.tableId);
      if(activeCfg) {
        getTableRows(activeCfg)
      } else {
        console.log("Table not open: " + lastNotification?.value?.tableKey)
      }
    }

    if(lastNotification?.type == "SetAsOnline" || lastNotification?.type == "SetAsOnline") {
      fetchBuddies();
    }
  }, [lastNotification]);

  useEffect(() => {
    setTables();
  }, []);

  //Update Active Cfgs
  useEffect(() => {
    if(cfgs == null) return;

    let newActiveCfgs = [...activeCfgs];
    cfgs.forEach(cfg => {
      let idx = newActiveCfgs.findIndex((i) => i.id == cfg.id)
      if(idx > -1) {
        newActiveCfgs[idx] = cfg;
      }
    });
    setActiveCfgs(newActiveCfgs);
  }, cfgs);

  function setCfgs(json) {
    let newCfgs = [];
    json.forEach(cfg => {
      let idx = newCfgs.indexOf((j) => j.id == cfg.id);
      if(idx == -1) {
        newCfgs.push(cfg);
      }
      let nestedTables = cfg.columns.filter((i) => i.fieldTypeName == "NestedTable");
      nestedTables.forEach(table => {
        if(table.configurationsJson == "") return;
        let configurationsJson = JSON.parse(table.configurationsJson);
        let innerCfg = json.find((innerCfg) => innerCfg.id == configurationsJson.tableId);
        console.log(innerCfg);
        //Search for cfg
        let idx = newCfgs.indexOf((i) => i.id == innerCfg.id);
        if(idx > -1) {
          newCfgs[idx].parentUniqueKeys.push(cfg.uniqueKey);
        } else {
          innerCfg.parentUniqueKeys = [cfg.uniqueKey];
          newCfgs.push(innerCfg);
        }
      });
    });
    setCfgsStatic(newCfgs);
  }


  //Styles
  useEffect(() => {
    fetchStyles();
  }, []);

  //Companies
  useEffect(() => {
    let url = BASE_URL + "/api/Companies";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userState.token);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    callApiAsync(url, requestOptions, (response) => {
      setCompanies(response);
    });
  }, []);

  //Buddies
  useEffect(() => {
    fetchBuddies();
  }, [])
  

  //USER
  let me = useMemo(() => {
    return(buddies?.find((i) => i.userName == userState.userState.username));
  }, [buddies]);

  let currentCompany = useMemo(() => {
    return(companies?.find((i) => i.id == me.companyId));
  }, [buddies]);


  //Values per selectedPoiCfg
  let selectedStyle = useMemo(() => {
    return(selectedCfg ? 
      selectedStyles[selectedCfg.uniqueKey] ? 
      selectedStyles[selectedCfg.uniqueKey]
      : []
    : [])
  }, [selectedCfg, selectedStyles]);

  let selectedTableRows = useMemo(() => {
    return(selectedCfg ? 
      selectedRows[selectedCfg.uniqueKey] ? 
      selectedRows[selectedCfg.uniqueKey]
      : []
      : [])
    }, [selectedCfg, selectedRows]);

  let selectedTableFilters = useMemo(() => {
    return(selectedCfg ? 
      selectedFilters[selectedCfg.uniqueKey] ? 
      selectedFilters[selectedCfg.uniqueKey]
      : {items:[]}
      : {items:[]})
    }, [selectedCfg, selectedFilters]);

  let hasMapLayer = useMemo(() => {
    return(selectedCfg ? selectedCfg.columns.find((row) => row.type == "vectorOverlay") != null : false)
    }, [selectedCfg]);

  let dataEntriesByUniqueKey = useMemo(() => {
    return(dataEntries && selectedCfg ? dataEntries[selectedCfg.uniqueKey] : [])
  }, [selectedCfg, dataEntries]);

  let selectedPoisByUniqueId = useMemo(() => (
    selectedPois && selectedCfg ? selectedPois[selectedCfg.uniqueKey] : []
  ), [selectedCfg, selectedPois, dataEntries]);


  //SET WEBSITE TITLE
  useEffect(() => {
    document.title = me?.companyName ? "W.I.V - " + me.companyName : "WOOD.IN.VISION";
  }, [me]);

  return(
    <DataContext.Provider
      value={{
        BASE_URL,

        companies,
        currentCompany,

        me,
        buddies,

        userId,
        locationSharedConfig,
        tableAccessConfigId,
        userSettings,

        serverSyncState,
        cfgs,
        activeCfgs,
        selectedCfg,
        styles,
        selectedStyles,
        dataEntries,
        mapConfigs,
        selectedPois,
        selectedBlobs,
        selectedRows,
        showArchived,
        selectedFilters,
        layers,
        favLayers,
        enabledBaseLayer,
        enabledLayers,
        enabledFavLayer,

        selectedStyle,
        selectedTableRows,
        selectedTableFilters,
        hasMapLayer,
        dataEntriesByUniqueKey,
        selectedPoisByUniqueId,

        setServerSyncState,
        setCfgs,
        setActiveCfgs,
        setSelectedCfg,
        setStyles,
        setSelectedStyles,
        setDataEntries,
        setMapConfigs,
        setSelectedPois,
        setSelectedBlobs,
        setSelectedRows,
        setShowArchived,
        setSelectedFilters,
        setFavLayers,
        setEnabledBaseLayer,
        setEnabledLayers,
        setEnabledFavLayer,

        getTableRows,

        statusMsg,
        setStatusMsg,

        editUserSetting,
        saveUserSetting,
        saveCompleteUserSettings,

        setTables
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export const useServerData = () => useContext(DataContext);