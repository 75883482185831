import "../../App.css";
import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  Snackbar,
  Switch,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import {AdminPanelSettings, Groups, Map, Settings, Style, WarningAmberOutlined, Wysiwyg} from "@mui/icons-material";
import NavBarItem from "../../widgets/navBar/NavBarItem";
import BarChartIcon from "@mui/icons-material/BarChart";
import MapView from "../mapView/MapView";
import "./MainView.css";
import { frontend_version, WIV_LOGO, WIV_LOGO_ONLY } from "../../js/defines";
import { deDE } from "@mui/material/locale";
import TableConfiguration from "../adminConfiguration/tableConfiguration";
import CompanyUserConfiguration from "../adminConfiguration/companyUserConfiguration";
import { useServerData } from "../../providers/DataProvider";
import StyleConfiguration from "../adminConfiguration/styleConfiguration";
import { useAuth } from "../../providers/AuthProvider";
import { getShouldInvertFromHex } from "../../js/helper";
import GroupConfiguration from "../adminConfiguration/groupConfiguration";

function MainView(props) {

  const { userState, setUserState } = useAuth();

  const { me, statusMsg, setStatusMsg, cfgs, userSettings, saveUserSetting, saveCompleteUserSettings } = useServerData();

  const [adminDialogOpen, setAdminDialogOpen] = useState(false);
  const [adminPW, setAdminPW] = useState("");

  const [selectedIndex, setSelectedIndex] = useState(userState?.userState?.isAdmin ? "admin_configuration" : "main");
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [currentDbUrl, setCurrentDbUrl] = useState("https://wiv.app.wood-in-vision.com");
  const [themeType, setThemeType] = useState(userSettings?.themeMode ?? "light");
  useEffect(() => setThemeType(userSettings?.themeMode ?? "light"), [userSettings?.themeMode]);
  const theme = createTheme({
    palette: {
      mode: themeType == "dark" ? "dark" : "light",
      primary: {
        main: '#009900',
      },
      background: {
        default: themeType == "dark" ? "#333" : "#eee",
        paper: themeType == "dark" ? "#232323" : "#ddd"
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '16px',
            boxShadow: "none"
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
    }
  }, deDE);
  const [tabSize, setTabSize] = useState(userSettings?.tabSize ?? "comfort");
  useEffect(() => setTabSize(userSettings?.tabSize ?? "comfort"), [userSettings?.tabSize]);


  //UserSettings
  function onThemeChanged(themeType) {
    setThemeType(themeType);
    saveUserSetting("themeMode", themeType);
  }

  function onTabSizeChanged(tabSize) {
    setTabSize(tabSize);
    saveUserSetting("tabSize", tabSize);
  }

  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [sites, setSites] = useState([
    {
      id: "main",
      name: "MapView",
      icon: <Map style={{ color: "white" }} />,
    },
    /*{
      id: "admin_configuration",
      name: "Admin Configurator",
      icon: <AdminPanelSettings style={{ color: "white" }} />,
    }*/
  ]);

  const [adminSites, setAdminSites] = useState([
    {
      id: "table_configuration",
      name: "Table Configurator",
      icon: <Wysiwyg style={{ color: "white" }} />,
    },
    {
      id: "style_configuration",
      name: "Style Configurator",
      icon: <Style style={{ color: "white" }} />,
    },
    {
      id: "group_configuration",
      name: "Group Configurator",
      icon: <Groups style={{ color: "white" }} />,
    },
    {
      id: "admin_configuration",
      name: "Company Configurator",
      icon: <AdminPanelSettings style={{ color: "white" }} />,
    }
  ]);

  const [functions, setFunctions] = useState([
    {
      id: "dashboard",
      name: "Dashboard",
      icon: <BarChartIcon style={{ color: "white" }} />,
      url: "https://wiv.app.wood-in-vision.com/"
    }
  ]);

  useEffect(() => {
    setFunctions([
      {
        id: "dashboard",
        name: "Dashboard",
        icon: <BarChartIcon style={{ color: "white" }} />,
        url: props.userSettings && props.userSettings.grafanaUrl ? userSettings.grafanaUrl : ""
      }
    ]);
  }, [userSettings])


  function getSiteContent() {
    switch (selectedIndex) {
      case "main":
        return <MapView
            userState={userState}
            bearerToken={userState.token}
            userSettings={userSettings}
            tabSize={tabSize}
            onSaveCompleteUserSetting={onSaveCompleteUserSetting}
          />;
      case "table_configuration":
        return <TableConfiguration bearerToken={userState.token}/>;
      case "style_configuration":
        return <StyleConfiguration bearerToken={userState.token}/>;
      case "group_configuration":
      return <GroupConfiguration bearerToken={userState.token}/>;
      case "admin_configuration":
        return <CompanyUserConfiguration bearerToken={userState.token}/>;
      case "dashboard":
        return (
          <>
          <iframe 
            id="grafana-iframe"
            display="block"
            width="100%"
            frameBorder="0"
            padding="0"
            margin="0"
            content="width=device-width, initial-scale=1.0"
            title="Grafana"
            src={currentDbUrl}
            
          ></iframe>
          </>
        );
      case 2:
        return <p>1</p>;
      case 3:
        return <p>2</p>;
      default:
        return <div>SITE NOT FOUND</div>;

    }
  }

  function onSaveCompleteUserSetting(newUserSettings) {
    console.log(newUserSettings)
    saveCompleteUserSettings(newUserSettings);
  }

  useEffect(() => {
    window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      e.returnValue = '';
    });
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--mui-backgroundcolor", theme.palette.background.default);
    document.documentElement.style.setProperty("--mui-backgroundpaper", theme.palette.background.paper);
    document.documentElement.style.setProperty("--mui-fontcolor", theme.palette.action.active);
    document.documentElement.style.setProperty("--mui-fontfilter", themeType == "dark" ? "invert(1)" : "");
    document.documentElement.style.setProperty("--mui-backgroundcolorhover", themeType == "dark" ? "#717171" : "#c8c8c8");
    document.documentElement.style.setProperty("--scrollbar", themeType == "dark" ? "rgb(255, 255, 255, 0.1)" : "rgb(0, 0, 0, 0.1)");
    document.documentElement.style.setProperty("--scrollbar-hover", themeType == "dark" ? "rgb(255, 255, 255, 0.2)" : "rgb(0, 0, 0, 0.25)");
  }, [themeType]);

  return (
    <ThemeProvider theme={theme}>
    {cfgs != null && userSettings != null ?
    <div style={{ display: "flex", height: "100svh" }}>
      {/*NAVBAR*/}
      <div className="side-navbar" style={{ width: "70px", height: "100svh", backgroundColor: "#222222", display: "flex", flexDirection: "column", position: "relative" }}>

        {/*<div
          alt="WOOD.IN.VISION Logo"
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            userSelect: "none",
            backgroundPosition: "center bottom",
            backgroundImage: "url(" + bg_gradient + ")",
            backgroundRepeat: "repeat",
            backgroundSize: 72,
            zIndex: 0,
            animation: "blurAnimationNavbar 4s ease-in forwards",
          }}
          onDragStart={(e) => e.preventDefault()}
        />*/}

        <div className="div-logo" style={{ width: "70px", height: "70px", zIndex: 1 }}>
          <img src={WIV_LOGO_ONLY} alt="WOOD.IN.VISION Logo" width="58px" style={{ position: "absolute", top: 6, left: 6, zIndex: 1, filter: "blur(20px)"}}/>
          <img src={WIV_LOGO_ONLY} alt="WOOD.IN.VISION Logo" width="58px" style={{ position: "absolute", top: 6, left: 6, zIndex: 2}}/>
        </div>

        <div className="div-navigation" style={{width: "70px", flex: "auto", overflow: "hidden"}}>
          {sites.map((site, i) => {
            return (
              <NavBarItem
                key={i}
                site={site}
                selectedIndex={site.id === selectedIndex}
                onClick={() => {
                  setSelectedIndex(site.id);
                }}
              />
            );
          })}
          {/*<Divider orientation="horizontal" style={{backgroundColor: "#777", marginInline: "8px"}}></Divider>
          functions.map((site, i) => {
            return (
              <NavBarItem
                key={i}
                site={site}
                selectedIndex={site.id === selectedIndex}
                onClick={() => {
                  var params = [
                    'height=' + window.screen.height,
                    'width=' + window.screen.width,
                    'fullscreen=yes'
                ].join(',');
                if(site.url != "") {
                  var popup = window.open(site.url, 'popup_window', params); 
                  popup.moveTo(0,0);
                } else {
                  alert("No Dashboard url configured");
                }
                }}
              />
            );
          })*/}
          {userState?.userState?.isAdmin ? 
            <>
              <Divider orientation="horizontal" style={{backgroundColor: "#777", marginInline: "8px"}}></Divider>
              {adminSites.map((site, i) => {
                return (
                  <NavBarItem
                    key={i}
                    site={site}
                    selectedIndex={site.id === selectedIndex}
                    onClick={() => {
                      setSelectedIndex(site.id);
                    }}
                  />
                );
              })}
            </> : null
          }
        </div>
        <div className="div-account" style={{ width: "70px", height: "170px" }}>
          <Tooltip title="Einstellungen" placement="right">
            <IconButton style={{ margin: "15px", color: "white" }} onClick={handleClick}>
              <Settings/>
            </IconButton>
          </Tooltip>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={open}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => setAdminDialogOpen(true)} style={{ backgroundColor: 'transparent', gap: "4px" }}>
              Administration
            </MenuItem>
            <MenuItem disableRipple style={{ backgroundColor: 'transparent', gap: "4px", cursor: "default" }}>
              Kompakte Tabs
              <Switch size="small" checked={tabSize == "small"} onClick={(e) => {
                onTabSizeChanged(e.target.checked ? "small" : "comfort");
              }}></Switch>
            </MenuItem>
            <MenuItem disableRipple style={{ backgroundColor: 'transparent', gap: "4px", cursor: "default" }}>
              Dunkler Modus
              <Switch size="small" checked={themeType == "dark"} onClick={(e) => {
                onThemeChanged(e.target.checked ? "dark" : "light");
              }}></Switch>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => setEditUserDialogOpen(true)} style={{ backgroundColor: 'transparent', gap: "4px", cursor: "default" }}>
              <Avatar alt={userState.fullName} src={me?.profilePictureBase64}/>
              <ListItemText primary={me?.fullname} secondary={userState?.userState?.tableAccessConfig?.groupName} style={{ color: theme.palette.text.primary }} />
            </MenuItem>
          </Menu>
          <Tooltip title="Abmelden" placement="right">
            <IconButton
              style={{ margin: "15px", color: "white" }}
              onClick={() => setLogoutDialogOpen(true)}
            >
              <LogoutIcon/>
            </IconButton>
          </Tooltip>
          <Typography color={"white"} fontSize={12}  style={{textAlign: "center", padding: "0px", userSelect: "none"}}>v{frontend_version}</Typography>
        </div>
      </div>
      {/*CONTENT*/}
      {getSiteContent()}
      <Dialog open={logoutDialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Abmelden</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher, das Sie sich abmelden möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "grey" }} onClick={() => setLogoutDialogOpen(false)}>
            Abbrechen
          </Button>
          <Button variant="contained" onClick={() => setUserState(null)} autoFocus>
            Abmelden
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editUserDialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Edit User</DialogTitle>
        <DialogContent>
          EDIT USER
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "grey" }} onClick={() => setEditUserDialogOpen(false)}>
            Abbrechen
          </Button>
          <Button variant="contained" /*onClick={() => props.onLogout()}*/ autoFocus>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={statusMsg != null && statusMsg?.type != "Error"}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={statusMsg?.message}
        color="#009900"
        autoHideDuration={2000}
        TransitionComponent={Slide}
        onClose={() => setStatusMsg(null)}
        sx={{
          '& .MuiSnackbarContent-root': {
            borderRadius: "12px",
            backgroundColor: "#009900",
            color: getShouldInvertFromHex("#009900"),
          },
        }}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />

      <Dialog open={statusMsg != null && statusMsg?.type == "Error"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{display: "flex", gap: 8}}>
            {statusMsg?.type == "Error" ?
              <WarningAmberOutlined style={{alignSelf: "center"}} color='error'/>
              :
              <WarningAmberOutlined style={{alignSelf: "center"}} color='error'/>
            }
            <Typography fontSize={20}>Error</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          {statusMsg?.message}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setStatusMsg(null)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={adminDialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Administration</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{padding: "20px", display: "flex", flexDirection: "column", gap: "20px"}}>
              {adminPW == "test123" ?
                <>
                  <TextField multiline rows={8} label="layerConfig" value={userSettings && userSettings.mapLayers != null ? JSON.stringify(userSettings.mapLayers) : ""} onChange={(e) => saveUserSetting("mapLayers", JSON.parse(e.target.value))}></TextField>
                  <TextField multiline rows={8} label="favLayerConfig" value={userSettings && userSettings.favLayers != null ? JSON.stringify(userSettings.favLayers) : ""} onChange={(e) => saveUserSetting("favLayers", JSON.parse(e.target.value))}></TextField>
                  <TextField label="grafanaUrl" value={userSettings.grafanaUrl} onChange={(e) => saveUserSetting("grafanaUrl", e.target.value)}></TextField>
                  <Typography>Kundenlogo</Typography>
                  <div style={{display: "flex", flexWrap: "wrap"}}>
                    <input id="file" type="file" accept="image/png,image/jpeg,image/jpg,image/svg+xml" /*onChange={handleFileChange}*/ />
                    <img style={{width: "200px"}} key={userSettings && userSettings.companyLogo ? userSettings.companyLogo : ""} src={userSettings && userSettings.companyLogo ? userSettings.companyLogo : WIV_LOGO}></img>
                  </div>
                </>
                : <TextField label="AdminPW" onChange={(e) => setAdminPW(e.target.value)}></TextField>
              }
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "grey" }} onClick={() => setAdminDialogOpen(false)}>Schließen</Button>
          <Button variant="contained" onClick={() => setAdminDialogOpen(false)} autoFocus>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      {false ?
        <div className="dev-watermark" style={{color: userState?.userState?.isAdmin ? "blue" : userState?.userState?.roles.includes("CompanyAdmin") ? "orange" : "red", fontWeight: "bold"}}>
          DEV VERSION<br/>
          User: {me?.fullname} {userState?.userState?.isAdmin ? " - Admin" : ""}<br/>
          Company: {me?.companyName}<br/>
          © WOOD.IN.VISION 2024
        </div> : null}

    </div>
    :
    <div className="bg">
      <div className="bg-overlay"/>
      <div style={{ height: "100svh", width: "100%", top: "0px", overflow: "clip" }}>
        <div className="loading-div">
          <img src={WIV_LOGO} style={{width: "100%"}}></img>
          <LinearProgress style={{width: "96%", marginLeft: "2%"}}/>
          <Typography fontWeight={600} style={{color: "#A3A397", textAlign: "center"}}>LADE DATEN</Typography>
        </div>
      </div>
    </div>    
    }
    </ThemeProvider>
  );
}

export default MainView;